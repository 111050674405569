import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import { getUrl, formatDate } from '../../planner/planner';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';

export default function Shortages(props) {
		const [orders, setOrders] = useState([]);
		const [bom, setBom] = useState([]);
		const [lastSynced, setLastSynced] = useState([]);
		var [loading, setLoading] = useState(true);

		function getOrders() {
			if(loading) {
				var url = getUrl() + '/wp-json/wc/v3/orders?jobs=shortage';

				axios.get(url, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then(res => setOrders(res.data), setLoading(false)).catch(err => console.log(err));
			}

			var bomArray = [];

			orders.forEach(order => {
				order.bom.forEach(bom => {
					if(bom.stock < bom.quantity) {
						bomArray.push({
							id: order.id,
							sku: bom.sku,
							free_stock: bom.stock,
							quantity: bom.quantity,
							type: bom.type.name,
							due_date: bom.due_date
						})
					}
				})
			});

			setBom(bomArray);
		}

		useEffect(() => {
			getOrders();

			var url = getUrl() + '/wp-json/wc/v3/planner/settings';

			axios.get(url, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
			.then(res => setLastSynced(res.data.last_synced)).catch(err => console.log(err));	
		}, [orders]);

		function headerTemplate(data) {
			return <strong><Link to={'/job/' + data.id}>Order #{data.id}</Link></strong>
		}

		function productType(data) {
			return <span className="badge bg-primary">{data.type}</span>
		}

		return (
			<>
				<h4 className="mb-3">Shortage Orders</h4>
				<DataTable value={bom} rowGroupMode="subheader" groupRowsBy="id" rowGroupHeaderTemplate={headerTemplate} tableStyle={{ minWidth: '30rem' }} paginator rows={10}>
					<Column field="sku" header="Product"></Column>
					<Column field="free_stock" header="Free Stock"></Column>
					<Column field="quantity" header="Required Stock"></Column>
					<Column field="type" header="Product Type" body={productType}></Column>
					<Column field="due_date" header="Due Date"></Column>
				</DataTable>
			</>
		);
}