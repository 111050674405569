import Moment from 'moment';

/**
 * @returns The url for the headless application
 */
export function getUrl() {
	return 'https://test-planner-api.cellpacksolutions.co.uk';
}

/**
 * Function which outputs the time formatting for the system
 * 
 * @param {int} time The number of minutes
 * @param {String} unit The unit of time that should be outputted
 * 
 * @returns {String} The formatted time
 */
export function formatTime(time, unit) {
	let formattedTime;

	if(unit === 'hour') {
		let hours = Math.round(time / 60);

		formattedTime = hours + ' Hour' + (hours !== 1 ? 's' : '');
	} else if(unit === 'minute') {
		let minutes = Math.round(time / 60);

		formattedTime = minutes + ' Minute' + (minutes !== 1 ? 's' : '');
	} else {
		formattedTime = time;
	}

	return formattedTime;
}

/**
 * @returns The date formatting the system should use
 */
export function getDateFormat() {
	return 'DD/MM/YYYY';
}

/**
 * @returns Adds the timing formatting the system uses onto the date format
 */
export function getDateTimeFormat() {
	return getDateFormat() + ' @ HH:mm:ss';
}

/**
 * @returns Adds the timing formatting the system uses onto the date format
 */
export function getISODateTimeFormat() {
	return 'YYYY-MM-DDTHH:mm:ss';
}

export function getJobTimeFormat(totalSeconds = 0) {
	let hours = Math.floor(totalSeconds / 3600).toString().padStart(2, '0');
	let minutes = (Math.floor(totalSeconds / 60) % 60).toString().padStart(2, '0');
	let seconds = Math.floor(totalSeconds % 60).toString().padStart(2, '0');

	if(isNaN(hours)) {
		hours = '00';
	}

	if(isNaN(minutes)) {
		minutes = '00';
	}

	if(isNaN(seconds)) {
		seconds = '00';
	}

	return hours + ':' + minutes + ':' + seconds;
}

export function getJobTimeBadge(actualTime, targetTime, defaultText = 'Not started', forceClass = null) {
	let badgeClass = 'bg-dark';
	let text       = defaultText;
	
	if(actualTime > 0) {
		text = getJobTimeFormat(actualTime);
		badgeClass = 'bg-success';
	}

	if(targetTime) {
		if(actualTime > targetTime) {
			badgeClass = 'bg-warning';
		}

		if(actualTime > (targetTime + ((targetTime / 100) * 5))) {
			badgeClass = 'bg-danger';
		}
	} else {
		badgeClass = 'bg-dark';
	}
	
	if(forceClass) {
		badgeClass = forceClass;
	}

	return <span className={badgeClass + " badge text-white"}><i className="pi pi-stopwatch me-1" style={{ fontSize: '0.65rem' }} ></i>{text}</span>
}

/**
 * A function to format the date returned by the REST API and if the date should be highlighted or not if it has passed
 * 
 * @param {String} date The date to be formatted
 * @param {Boolean} highlight A boolean if the date should be highlighted if after the current date
 * @param {Boolean} time A boolean to include the time as well as the date
 * 
 * @returns HTML of the current date in the correct format
 */
export function formatDate(date, time = false, highlight = false, invalidDateText = 'No Date Set') {
	if(date) {
		let dateFormat = getDateFormat();

		if(time) {
			dateFormat = getDateTimeFormat();
		}

		let formattedDate = Moment(date).format(dateFormat);

		if(formattedDate === 'Invalid date') {
			return invalidDateText;
		}

		if(Moment(date).isBefore(Moment()) && highlight) {
			return <span className="badge bg-danger text-white">{formattedDate}</span>
		} else {
			return formattedDate;
		}
	}
}

/**
 * A function to format the date returned by the REST API and if the date should be highlighted or not if it has passed
 *
 * @param {String} date The date to be formatted
 *
 * @returns HTML of the current date in the correct format
 */
export function formatTrafficDate(date, status = 'inputted', forceClass = '') {
	let isCompleted = false;

	if(status === 'Completed') {
		isCompleted = true;
	}

	if(date) {
		let dateFormat = getDateFormat();

		let formattedDate = Moment(date.replace("\/", "/")).format(dateFormat);

		if(formattedDate === 'Invalid date') {
			return 'No Date Set';
		}

		if(Moment(date).isBefore(Moment()) && isCompleted === false && forceClass != '') {
			return <span className="badge bg-danger text-white">{formattedDate}</span>
		} else if (Moment(date).subtract(7, "days").isBefore(Moment()) && isCompleted === false && forceClass != '') {
			return <span className="badge bg-warning text-white">{formattedDate}</span>;
		} else {
			if(forceClass) {
				return <span className={"badge " + forceClass + " text-white"}>{formattedDate}</span>;
			} else {
				return <span className="badge bg-success text-white">{formattedDate}</span>;
			}
		}
	}
}