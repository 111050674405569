import React from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Fieldset } from 'primereact/fieldset';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { FilterMatchMode } from 'primereact/api';
import { Link } from 'react-router-dom';
import { decode } from 'html-entities';
import { Toast } from 'primereact/toast';
import { getUrl, formatDate } from './../planner/planner';

class EditSuggestionForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			suggestion: [],
			isLoaded: false,
			locked: false,
			suggestionContent: '',
			quantity: 1,
			travellers: [],
			note: '',
			noteDialog: false,
			operators: [],
			operator: null,
			comments: [],
			selectedStatus: null,
			filters: {
				meta: { value: null, matchMode: FilterMatchMode.CONTAINS }
			},
			selectedStatusFilter: null,
			status: '',
			traveller: null,
			toast: React.createRef()
		}

		this.getCommentAuthor = this.getCommentAuthor.bind(this);
	}

	/**
	 * Lock the scrap to the ID that is editing
	 * 
	 * @param {Object} scrapDetails The data for the current scrap
	 */
	lockSuggestion(suggestionDetails) {
		if(suggestionDetails.data.locked === false) {
			axios.put(getUrl() + '/wp-json/planner/v1/travellers/suggestions/' + this.props.id, {
				"locked" : Math.floor(Date.now() / 1000) + ':' + this.props.currentLoggedInUser.id
			}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
			.then(res => this.setState({
				isLoaded: true
			})).catch(err => console.log(err))
		}
	}

	/**
	 * Checks if the scrap is being edited by another user
	 * 
	 * @returns {Boolean} Returns true or false depending if the scrap is currently locked
	 */
	isSuggestionLocked() {
		if(this.state.locked === false || this.props.currentLoggedInUser.id === this.state.locked.ID) {
			return false;
		} else {
			return true;
		}
	}

	/**
	 * Runs when the component mounts
	 */
	async componentDidMount() {
		const [getSuggestionDetails] = await Promise.all([
			axios.get(getUrl() + '/wp-json/planner/v1/travellers/suggestions/' + this.props.id, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }),
		]);

		const [gettravellers] = await Promise.all([
			axios.get(getUrl() + '/wp-json/planner/v1/travellers/', { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }),
		]);

		const [getOperators] = await Promise.all([
			axios.get(getUrl() + '/wp-json/planner/v1/users/production_operator', { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }),
		]);

		this.setState({
			suggestion: getSuggestionDetails.data,
			locked: getSuggestionDetails.data.locked,
			suggestionContent: getSuggestionDetails.data.suggestion,
			travellers: gettravellers.data,
			operators: getOperators.data,
			operator: getSuggestionDetails.data.operator,
			status: getSuggestionDetails.data.status,
			comments: getSuggestionDetails.data.notes,
			traveller: getSuggestionDetails.data.traveller.id,
			isLoaded: true,
		});

		document.title = 'Suggestion #' + getSuggestionDetails.data.number + ' - Planner';

		setInterval(() => {
			this.lockSuggestion(getSuggestionDetails);
		}, 15000);

		this.lockSuggestion(getSuggestionDetails);
	}

	async changeTraveller(event) {
		this.setState({
			traveller: event.value,
		});

		await axios.put(getUrl() + '/wp-json/planner/v1/travellers/suggestions/' + this.props.id, {
			"traveller" : event.value,
		}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then().catch(err => console.log(err));

		this.state.toast.current.show({ severity: 'success', summary: 'Updated', detail: 'Traveller updated.' });

		this.updateOrderNotes();
	}

	async changeOperator(event) {
		this.setState({
			operator: event.value,
		});

		await axios.put(getUrl() + '/wp-json/planner/v1/travellers/suggestions/' + this.props.id, {
			"operator" : event.value,
		}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then().catch(err => console.log(err));

		this.state.toast.current.show({ severity: 'success', summary: 'Updated', detail: 'Operator updated.' });

		this.updateOrderNotes();
	}

	/**
	 * Update the state of the order notes
	 */
	updateOrderNotes() {
		axios.get(getUrl() + '/wp-json/planner/v1/travellers/suggestions/' + this.props.id, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then(res => this.setState({
			comments: res.data.notes,
		}))
		.catch(err => console.log(err));
	}

	async changeStatus(event) {
		this.setState({
			status: event.target.value,
		});

		await axios.put(getUrl() + '/wp-json/planner/v1/travellers/suggestions/' + this.props.id, {
			"status" : event.target.value,
		}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then().catch(err => console.log(err));

		this.state.toast.current.show({ severity: 'success', summary: 'Updated', detail: 'Status updated.' });

		this.updateOrderNotes();
	}

	/**
	 * Create a note for the suggestion
	 */
	async addNote() {
		if(this.state.note) {
			this.setState({
				noteDialog: false
			});

			const { toast } = this.state;

			await axios.put(getUrl() + '/wp-json/planner/v1/travellers/suggestions/' + this.props.id, {
				"note" : {note: this.state.note, author: this.props.currentLoggedInUser.id} 
			}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then().catch(err => console.log(err));

			toast.current.show({ severity: 'success', summary: 'Suggestion', detail: 'Suggestion details have been updated.' });

			this.setState({
				note: ''
			});

			this.updateOrderNotes();
		} else {
			this.state.toast.current.show({ severity: 'error', summary: 'Note', detail: 'Please enter text in the note field.' });
		}
	}

	getDate(rowData) {
		return formatDate(rowData.content.comment_date, true);
	}

	getType(rowData) {
		let badgeClass;

		if(rowData.meta === 'System') {
			badgeClass = 'bg-dark';
		} else if (rowData.meta === 'User') {
			badgeClass = 'bg-primary';
		}

		return <span className={"badge " + badgeClass}>{rowData.meta}</span>;
	}

	rowNumber(data, props) {
		return this.state.comments.length - props.rowIndex;
	}

	/**
	 * Update the status filtering based on what the user has selected
	 * 
	 * @param {input} e Information related to the current input
	 */
	onStatusFilterChange(e) {
		const value = e.target.value;

		let _filters = { ...this.state.filters };

		_filters['meta'].value = value;

		this.setState({filters: _filters, selectedStatusFilter: value });
	}

	getSuggestionTitle() {
		if(this.props.capabilities.edit_travellers=== true || this.props.capabilities.view_travellers === true) {
			return 'Editing Suggestion: #' + this.state.suggestion.number;
		} else {
			return 'Access Denied';
		}
	}

	resetFilter() {
		this.setState({
			selectedStatusFilter: null,
			filters: {
				meta: { value: null, matchMode: FilterMatchMode.CONTAINS }
			}
		});
	}

	optionsTemplate(e) {
		if (e) {
			return (
				<div className="flex align-items-center">
					<div>{decode(e.title)}</div>
				</div>
			);
		}

		return '';
	}

	optionTemplate(e) {
		return (
			<div className="flex align-items-center">
				<div>{decode(e.title)}</div>
			</div>
		);
	}

	getCommentContent(rowData) {
		return decode(rowData.content.comment_content);
	}

	getCommentAuthor(rowData) {
		let hasLink = false;

		if(rowData.roles && this.props.capabilities.edit_users === true) {
			hasLink = rowData.roles.includes('production_operator');
		}

		if(hasLink) {
			return <Link to={'/employee/' + rowData.content.user_id}>{rowData.content.comment_author}</Link>
		} else {
			return rowData.content.comment_author
		}
	}

	capitaliseFirstLetter(text) {
		return text[0].toUpperCase() + text.substring(1);		
	}

	render() {
		const { isLoaded, toast, noteDialog, suggestion, suggestionContent,  operators, operator, travellers, traveller, status, note, comments, filters } = this.state;

		return (
			<>
				<Toast ref={toast} position="bottom-right" />
				<div className="edit-order-bar position-sticky top-0 p-3">
					<div className="container px-0 d-flex justify-content-between align-items-center">
						<div className="d-flex">
							<div className="d-flex align-items-center">
								<Button className="bg-primary p-2 me-3 d-flex align-items-center rounded d-xxl-none" onClick={e => {this.props.onSidebarOpen(true);}}>
									<i className="pi pi-bars text-white" style={{ fontSize: '1.25rem' }}></i>
								</Button>
							</div>
							<div>
							{
								isLoaded && (
									<h1 className="mb-0 h3">{this.getSuggestionTitle()}</h1>
								)
							}
							{
								isLoaded && (
									<>
										<strong>Date Created:</strong>&nbsp;{formatDate(suggestion.date, true)}
									</>
								)
							}
							{
								!isLoaded && (
									<div>
										<i className="pi pi-spin pi-spinner" style={{ fontSize: '2.8rem' }}></i>
									</div>
								)
							}
							</div>
						</div>
						<div className="d-flex align-items-center">
						{
							isLoaded && this.props.capabilities.edit_travellers&& !this.isSuggestionLocked() && (
								<>
									<Button type="button" label="Add Note" icon="pi pi-comments" onClick={(e) => this.setState({noteDialog: true})} className="ms-3" />
								</>
							)
						}
						{
							this.isSuggestionLocked() && (
								<p className="d-inline mb-0 ms-3"><span className="badge bg-danger d-inline-flex align-items-center display-6"><i className="pi pi-lock me-2"></i> {this.state.locked.data.user_login} is currently editing this suggestion</span></p>
							)
						}
						</div>
					</div>
				</div>
				{
					isLoaded && (this.props.capabilities.edit_travellers|| this.props.capabilities.view_travellers) && ( // https://stackoverflow.com/questions/70682832/
					<>
						<Dialog blockScroll={true} header="Add Note" visible={noteDialog} style={{ width: '50vw' }} onHide={(e) => this.setState({noteDialog: false})}>
							<InputTextarea value={note} onChange={(e) => this.setState({note: e.target.value})} rows={5} maxLength={500} />
							<span>{(500 - note.length)+ " characters left"}</span><br />
							<Button type="button" label="Save Note" icon="pi pi-send" onClick={this.addNote.bind(this)} className="mt-4" />
						</Dialog>
						<form className="container px-4 px-xxl-0 mt-5">
							<div className="row">
								<div className="col-12">
									<Fieldset legend="Suggestion Information">
										<div className="container">
											<div className="row">
												<div className="col-12 col-lg-4">
													<strong className="w-100 mb-1 d-block">Traveller:</strong>
													{
														this.props.capabilities.view_travellers && (
															<Dropdown valueTemplate={this.optionsTemplate} itemTemplate={this.optionTemplate} filterInputAutoFocus={false} disabled={true} placeholder="Select a Traveller" value={parseInt(traveller)} options={travellers} optionLabel="title" optionValue="id" filter className="w-full md:w-14rem" onChange={this.changeTraveller.bind(this)} />
														)
													}
													{
														this.props.capabilities.edit_travellers&& (
															<Dropdown valueTemplate={this.optionsTemplate} itemTemplate={this.optionTemplate} filterInputAutoFocus={false} disabled={this.isSuggestionLocked()} placeholder="Select a Traveller" value={parseInt(traveller)} options={travellers} optionLabel="title" optionValue="id" filter className="w-full md:w-14rem" onChange={this.changeTraveller.bind(this)} />
														)
													}
												</div>
												<div className="col-12 col-lg-4">
													<strong className="w-100 mb-1 d-block">Operator:</strong>
													{
														this.props.capabilities.view_travellers && (
															<Dropdown filterInputAutoFocus={false} disabled={true} placeholder="Select an Operator" value={operator} options={operators} optionLabel="name" optionValue="id" className="w-full md:w-14rem" onChange={this.changeOperator.bind(this)} />
														)
													}
													{
														this.props.capabilities.edit_travellers&& (
															<Dropdown filterInputAutoFocus={false} disabled={this.isSuggestionLocked()} placeholder="Select an Operator" value={operator} options={operators} optionLabel="name" optionValue="id" className="w-full md:w-14rem" onChange={this.changeOperator.bind(this)} />
														)
													}
												</div>
												<div className="col-12 col-lg-4">
													<strong className="w-100 mb-1 d-block">Status:</strong>
													{
														this.props.capabilities.view_travellers && (
															<Dropdown filterInputAutoFocus={false} disabled={true} placeholder="Select a Status" value={this.capitaliseFirstLetter(status.replace('Planner-',''))} options={['Active', 'Dismissed', 'Resolved']} className="w-full md:w-14rem" onChange={this.changeStatus.bind(this)} />
														)
													}
													{
														this.props.capabilities.edit_travellers&& (
															<Dropdown filterInputAutoFocus={false} disabled={this.isSuggestionLocked()} placeholder="Select a Status" value={this.capitaliseFirstLetter(status.replace('Planner-',''))} options={['Active', 'Dismissed', 'Resolved']} className="w-full md:w-14rem" onChange={this.changeStatus.bind(this)} />
														)
													}
												</div>
											</div>
											<div className="col-12 mt-3">
												<strong className="w-100 mb-1 d-block">Suggestion:</strong>
												{suggestionContent}
											</div>
										</div>
									</Fieldset>
								</div>
							</div>
							<div className="row mt-5 mb-5">
								<div className="col-12">
									<Fieldset legend="Notes">
										<div className="container">
												<div className="row">
													<DataTable dataKey="content.comment_ID" value={comments} tableStyle={{ minWidth: '50rem' }} filters={filters} paginator rows={10}>
														<Column header="Note #" key="content.comment_ID" field="content.comment_ID" body={this.rowNumber.bind(this)} />
														<Column key="content.comment_date" field="content.comment_date" header="Date" body={this.getDate} style={{ minWidth: '12rem' }} />
														<Column key="meta" field="meta" header="Type" body={this.getType} />
														<Column key="content.comment_author" field="content.comment_author" header="Author" body={this.getCommentAuthor} />
														<Column key="content.comment_content" field="content.comment_content" header="Note" body={this.getCommentContent} />
													</DataTable>
												</div>
												<div className="row mt-3">
													<div className="d-flex justify-content-end">
														{
															this.state.selectedStatusFilter && (
																<div className="me-2">
																	<Button type="button" label="Reset" onClick={this.resetFilter.bind(this)} className="align-items-center" />
																</div>
															)
														}
														<div>
														<Dropdown placeholder="Filter by Type" value={this.state.selectedStatusFilter} options={['User', 'System']} className="w-full md:w-14rem" onChange={this.onStatusFilterChange.bind(this)} />
													</div>
												</div>
											</div>
										</div>
									</Fieldset>
								</div>
							</div>
						</form> 
					</>
				)
			}
			</>
    );
  }
}
export default EditSuggestionForm;