import React, { Component } from 'react';
import LowStock from './tables/low-stock';
import ProductStock from './charts/product-stock';
import MissingTimes from './tables/missing-times';
import ProductPopularity from './tables/product-popularity';
import axios from 'axios';

import { getUrl, formatDate } from '../planner/planner';

export class ProductDashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			lastSynced: '',
		}
	}

	componentDidMount() {
		var url = getUrl() + '/wp-json/wc/v3/planner/settings';

		axios.get(url, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then(res => this.setState({
			lastSynced: res.data.last_synced_products
		})).catch(err => console.log(err));
	}

	render() {
		return (<>
				<div className="edit-order-bar position-sticky top-0 py-3 mb-5">
					<div className="mx-5 px-0 d-flex justify-content-between align-items-center">
						<div>
							<h1 className="mb-0 h3">{this.props.title}</h1>
							<strong>Last Synced:</strong>&nbsp;{formatDate(this.state.lastSynced, true)}
						</div>
					</div>
				</div>
				<div className="container px-5 my-5">
					<div className="row">
						<div className="col-4">
							<ProductStock />
						</div>
						<div className="col-8">
							<MissingTimes title="Missing Battery Pack Timings" category={this.props.batteryPackId} lowStock={100} />
						</div>
					</div>
				</div>
				<div className="container px-5 my-5">
					<div className="row">
						<div className="col-4">
							<LowStock title="Components Low Stock" category={this.props.componentId} lowStock={100} />
						</div>
						<div className="col-4">
							<LowStock title="Cells Low Stock" category={this.props.cellId} lowStock={100} />
						</div>
						<div className="col-4">
							<ProductPopularity title="Popular Products" />
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default ProductDashboard