import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction";
import axios from 'axios';
import { getUrl } from '../planner/planner';
import { Fieldset } from 'primereact/fieldset';
import { Dialog } from 'primereact/dialog';
import Moment from 'moment';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import React, { useState } from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Checkbox } from 'primereact/checkbox';

export function HourBookings(props) {
	const [events, setEvents] = useState([]);
	const [dialog, setDialog] = useState(false);
	const [options, setOptions] = useState([]);
	const [type, setType] = useState(null);
	const [startDateTime, setStartDateTime] = useState(null);
	const [endDateTime, setEndDateTime] = useState(null);
	const [basicHours, setBasicHours] = useState([]);
	const [deletingEvent, setDeletingEvent] = useState(null);
	const [confirmRefreshDialog, setConfirmRefreshDialog] = useState(false);
	const [breaks, setBreaks] = useState({
		break_morning: false,
		break_lunch: false,
		break_afternoon: false
	});

	if(options.length == 0 || events.length == 0 || basicHours.length == 0) {
		axios.get(getUrl() + '/wp-json/planner/v1/employees/' + props.employeeId, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then(res => {
			if(res.data.bookings != false && events.length == 0) {
				setEvents(res.data.bookings);
			}

			if(basicHours != null) {
				setBasicHours(res.data.basic_hours);
			}

			if(options.length == 0) {
				setOptions(res.data.booking_options);
			}
		})
		.catch(err => console.log(err));
	}

	//console.log(basicHours);

	const onSelect = async (data) => {
		let { start, end } = data;
		setStartDateTime(start);
		setEndDateTime(end);

		setDialog(true);
	}

	function deleteBooking(event) {
		setDeletingEvent(event);
		setConfirmRefreshDialog(true);
	}

	function getBusinessHours() {
		let businessHours = [];

		if(basicHours) {
			basicHours.forEach(function(day) {
				let weekNumber = 0;

				if(day.day === 'Monday') {
					weekNumber = 1;
				} else if (day.day === 'Tuesday') {
					weekNumber = 2;
				} else if (day.day === 'Wednesday') {
					weekNumber = 3;
				} else if (day.day === 'Thursday') {
					weekNumber = 4;
				} else if (day.day === 'Friday') {
					weekNumber = 5;
				}

				if(day.start && day.end && weekNumber != 0) {
					businessHours.push({
						daysOfWeek: [weekNumber],
						startTime: day.start.substring(11, 16),
						endTime: day.end.substring(11, 16)
					})
				}
			});
		}

		return businessHours;
	}

	function getBackgroundColor(type) {
		let backgroundColor = '#6366F1';
		let textColor = '#fff';

		options.forEach(function(option) {
			if(type === option.option_name) {
				backgroundColor = option.colour_background;
				textColor = option.colour_text;
			}
		});

		return {
			backgroundColor: backgroundColor,
			textColor: textColor
		}
	}

	async function createHoliday() {
		let id;

		id = await axios.post(getUrl() + '/wp-json/planner/v1/bookings', {
			author_id: props.employeeId,
			category_id: props.bookingEmployeeId,
			date_from: Moment(startDateTime).format("YYYY-MM-DD HH:mm"),
			date_to: Moment(endDateTime).format("YYYY-MM-DD HH:mm"),
			user_id: props.employeeId,
			option_name: type,
			breaks: breaks
		}, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}}).then().catch(err => console.log(err));

		setEvents(
			events.concat({
				backgroundColor: getBackgroundColor(type).backgroundColor,
				borderColor: getBackgroundColor(type).backgroundColor,
				textColor: getBackgroundColor(type).textColor,
				title: type,
				start: Moment(startDateTime).format("YYYY-MM-DD HH:mm"),
				end: Moment(endDateTime).format("YYYY-MM-DD HH:mm"),
				id: id.data
			})
		);

		setDialog(false);
	}

	function deleteEvent() {
		let newEvents = [];

		events.forEach(function(event) {
			if(event.id != deletingEvent.event.id) {
				newEvents.push(event);
			}
		})

		setEvents(newEvents);

		axios.delete(getUrl() + '/wp-json/planner/v1/bookings/' + deletingEvent.event.id, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}}).then().catch(err => console.log(err));

		setDeletingEvent(null);
	}

	function setBreakIncludes(value, key) {
		let _breaks = {...breaks}

		if(key === 'morning') {
			_breaks.break_morning = value;

			setBreaks(_breaks);
		}

		if(key === 'lunch') {
			_breaks.break_lunch = value;

			setBreaks(_breaks);
		}

		if(key === 'afternoon') {
			_breaks.break_afternoon = value;

			setBreaks(breaks);
		}
	}

	return (<>
		<ConfirmDialog blockScroll={true} visible={confirmRefreshDialog} onHide={() => setConfirmRefreshDialog(false)} message="Are you sure you want to delete this booking?" header="Confirmation" icon="pi pi-exclamation-triangle" accept={deleteEvent} />
		<Fieldset legend="Bookings">
			<Dialog blockScroll={true} header="Add Booking" visible={dialog} closable={false}>
				<Dropdown
					options={options}
					optionLabel="option_name"
					optionValue="option_name"
					value={type}
					placeholder="Booking Type"
					className="w-100"
					style={{width: '100rem'}}
					onChange={(e) => setType(e.target.value)}
				/>
				<div>
					<strong class="d-block mt-3">Including</strong>
					<Checkbox onChange={e => setBreakIncludes(e.checked, 'morning')} checked={breaks.break_morning}></Checkbox> Morning Break<br />
					<Checkbox onChange={e => setBreakIncludes(e.checked, 'lunch')} checked={breaks.break_lunch}></Checkbox> Lunch Break<br />
					<Checkbox onChange={e => setBreakIncludes(e.checked, 'afternoon')} checked={breaks.break_afternoon}></Checkbox> Afternoon Break
				</div>
				<Button type="button" label="Save Booking" icon="pi pi-send" className="mt-4" onClick={createHoliday} />
			</Dialog>
			<FullCalendar
				headerToolbar={{
					center: 'dayGridMonth,timeGridWeek'
				}}
				plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
				initialView="timeGridWeek"
				selectable={true}
				contentHeight="auto"
				hiddenDays={[0]}
				selectOverlap={false}
				selectMirror={true}
				events={events}
				unselectAuto={false}
				select={onSelect}
				nowIndicator={true}
				slotLabelFormat={{
					hour: 'numeric',
					minute: '2-digit',
					omitZeroMinute: false,
					meridiem: false,
					hour12: false
				}}
				selectConstraint="businessHours"
				slotMinTime={"06:00"}
				slotMaxTime={"19:00"}
				eventDurationEditable={false}
				businessHours={getBusinessHours()}
				slotDuration={'00:30:00'}
				allDaySlot={true}
				allDayText={"All Day"}
				firstDay={1}
				views={{
					timeGridWeek: { // name of view
						titleFormat: {
							month: 'long',
							day: '2-digit',
							year: 'numeric'
						},
						dayHeaderContent: (e) => {
							return <div>{Moment(e.date).format('dddd Do')}</div>
						}
					}
				}}
				eventContent={(event) => {
					return(<>
						{
							event.event.allDay !== true && (
								<div className="d-flex justify-content-between align-items-center mx-1">
									<div>{event.timeText}</div>
									<div onClick={(e) => deleteBooking(event)} className="delete-button mt-1"><i className="pi pi-times" style={{ color: event.event.textColor }}></i></div>
								</div>
							)
						}
						<div className="mx-1">
							{event.event.title}
						</div>
					</>)
				}}
				/>
		</Fieldset>
	</>);
}
