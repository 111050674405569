import React from "react";
import ViewEmployee from './view-employee';
import { useLocation } from 'react-router-dom';

export default function ScrapWrapper(props) {
	const paths = useLocation().pathname.split('/');

	/**
	 * Update the sidebar
	 */
	function sidebarOpened() {
		props.updateSidebarOpened(true);
	}

	return (
		<ViewEmployee id={paths[paths.length - 1]} capabilities={props.capabilities} onSidebarOpen={sidebarOpened} bookingEmployeeId={props.bookingEmployeeId} />
	)
}