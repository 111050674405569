import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import { Card } from 'primereact/card';

import axios from 'axios';
import { getUrl } from '../../planner/planner';
 
export default function ProductStock() {

	const [inStock, setInStock] = useState([]);
	const [outOfStock, setOutOfStock] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [chartData, setChartData] = useState({});
	const [chartOptions, setChartOptions] = useState({});

		function get_stats() {
			if(!loaded) {
				var instockUrl = getUrl() + '/wp-json/wc/v3/products?stock_status=instock&per_page=999'; 
				axios.get(instockUrl, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then(res => setInStock(res.data.length), setLoaded(true)).catch(err => console.log(err));

				var outOfStockUrl = getUrl() + '/wp-json/wc/v3/products?stock_status=outofstock&per_page=999'; 
				axios.get(outOfStockUrl, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then(res => setOutOfStock(res.data.length), setLoaded(true)).catch(err => console.log(err));
			}
		}

		get_stats();

    useEffect(() => {

        const data = {
            labels: ["In Stock", "Out of Stock"],
            datasets: [
                {
                    data: [inStock, outOfStock],
                    backgroundColor: [
											"#093", 
											"#e4524a", 
                    ]
                }
            ]
        };

        const options = {
					plugins: {
						legend: {
							labels: {
									usePointStyle: true,
							},
							position: 'bottom'
						},
						title: {
							display: true,
							text: 'Product Stock',
							font: {
								size: 22,
								weight: 400
							}
						}
					}
        };

        setChartData(data);
        setChartOptions(options);
    }, [outOfStock, inStock]);

    return (
        <Card className="order-status-cart">
					<Chart type="doughnut" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
        </Card> 
    )
}