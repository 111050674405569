import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';

import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import { getUrl } from '../../planner/planner';

export default function ProductPopularity(props) {
	const [products, setProducts] = useState([]);
	const timeframeOptions = ['week', 'month', 'year'];
	const [selectedTimeframeOption, setTimeframeption] = useState('year');

	function getProducts() {
		var url = getUrl() + '/wp-json/wc/v3/reports/top_sellers?period=' + selectedTimeframeOption;

		axios.get(url, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then(res => setProducts(res.data)).catch(err => console.log(err));
	}

	useEffect(() => {
		getProducts();
	}, [selectedTimeframeOption]);

	return (
		<>
			<div className="d-flex align-items-end justify-content-between">
				<h4 className="mb-0">{props.title}</h4>
			</div>
			<DataTable value={products} className="mt-3" paginator rows={10}>
				<Column field="name" header="Product Code"></Column>
				<Column field="quantity" header="Total Sales"></Column>
			</DataTable>
			<div className="d-flex align-items-center justify-content-end mt-3">
				<div>
					<strong className="me-2">Timeframe: </strong>
				</div>
				<Dropdown value={selectedTimeframeOption} onChange={(e) => setTimeframeption(e.value)} options={timeframeOptions} placeholder="Timeframe" />
			</div>
		</>
	);
}