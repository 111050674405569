import React from 'react';
import axios from 'axios';
import { formatDate, getUrl, getJobTimeFormat } from '../planner/planner';
import { Calendar } from 'primereact/calendar';

import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Fieldset } from 'primereact/fieldset';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { Link } from 'react-router-dom';
import { decode } from 'html-entities';
import { Tooltip } from 'primereact/tooltip';
import { InputNumber } from 'primereact/inputnumber';

class EditOrderForm extends React.Component {
  constructor(props) {
    super(props);

		// Set default states
    this.state = {
			notes: null,
			date_confirmed: null,
			company_name: '',
			order_number: '',
			priority: '',
			status: '',
			order: [],
			planner: [],
			bom: [],
			due_dates: [],
			travellers: [],
			isLoaded: false,
			isLoading: true,
			isLocked: false,
			isAllocated: false,
			note: '',
			noteDialog: false,
			shouldUpdate: false,
			gotOrderInformation: false,
			toast: React.createRef()
		};

		// Bind functions to this
		this.dateChangeConfirmed = this.dateChangeConfirmed.bind(this);
		this.onRowEditComplete = this.onRowEditComplete.bind(this);
		this.getShortages = this.getShortages.bind(this);
		this.toggleAllocation = this.toggleAllocation.bind(this);
		this.addNote = this.addNote.bind(this);
		this.getProductSku = this.getProductSku.bind(this);
		this.getNoteAuthor = this.getNoteAuthor.bind(this);
	}

	/**
	 * Get information for the order and setup pinging the backend to lock the order to prevent other users from editing.
	 */
	async setupOrder() {
		if((this.props.capabilities.edit_jobs === true || this.props.capabilities.view_jobs === true) && this.state.isLoading === true) {
			this.setState({isLoading: false});

			// Get all details for the order
			const [getOrderDetails] = await Promise.all([
				axios.get(getUrl() + '/wp-json/planner/v1/orders/' + this.props.id, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }),
			]);

			if(this.state.travellers.length == 0 && this.props.capabilities.edit_travellers == true) {
				this.setState({
					travellers: getOrderDetails.data.travellers
				})
			}

			let confirmedDate = null;

			// If the confirmed date is set, get the date
			if(getOrderDetails.data.confirmed_date != null) {
				confirmedDate = new Date(getOrderDetails.data.confirmed_date);
			}

			// Set the states based on the API Calls
			this.setState({
				order: getOrderDetails.data,
				products: getOrderDetails.data.products,
				company_name: getOrderDetails.data.company_name,
				order_number: getOrderDetails.data.order_number,
				status: getOrderDetails.data.status,
				planner: getOrderDetails.data.wip,
				bom: getOrderDetails.data.bom,
				push_backs: getOrderDetails.data.order_push_backs,
				date_confirmed: confirmedDate,
				status: getOrderDetails.data.status,
				priority: getOrderDetails.data.wip.priority,
				flexibility: getOrderDetails.data.wip.flexible,
				jobTotal: getOrderDetails.data.total,
				notes: getOrderDetails.data.notes,
				isLocked: getOrderDetails.data.locked,
				gotOrderInformation: true,
				isLoaded: true,
			});

			// If the order is allocated, set the state
			if(getOrderDetails.data.allocation == 1) {
				this.setState({
					isAllocated: true
				})
			}

			// Set the document title
			document.title = 'Order #' + getOrderDetails.data.order_number + ' - Planner';

			// Lock the order for the next 15 seconds
			setInterval(() => {
				if(this.props.capabilities.edit_jobs === true) {
					this.lockOrder(getOrderDetails);
				}
			}, 15000);

			this.lockOrder(getOrderDetails);

			// Update the order notes
			this.updateOrderNotes();
		}
	}

	/**
	 * When the component mounts get all of the information for the order and store it
	 * into the component state and update the order notes.
	 */
	async componentDidMount() {
		this.setupOrder();
	}

	/**
	 * When the component mounts get all of the information for the order and store it
	 * into the component state and update the order notes.
	 */
	async componentWillReceiveProps() {
		this.setupOrder();
	}

	/**
	 * Lock the order to the ID that is editing
	 * 
	 * @param {Object} orderDetails The data for the current order
	 */
	lockOrder(orderDetails) {
		if(orderDetails.data.locked === false) {
			axios.put(getUrl() + '/wp-json/planner/v1/orders/' + this.props.id, {
				"locked" : Math.floor(Date.now() / 1000) + ':' + this.props.currentLoggedInUser.id
			}, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}})
			.then(res => this.setState({
				isLoaded: true
			})).catch(err => console.log(err))
		}
	}

	/**
	 * Checks if the component should update based on if an order status has changed or the props for the component is different
	 * 
	 * @param {Object} prevProps The previous state of the props
	 * @param {Object} prevState The previous state of the state
	 */
	componentDidUpdate(prevProps, prevState) {
		if(this.state.notes !== prevState.notes && this.state.shouldUpdate !== prevState.shouldUpdate) {
			this.updateOrderNotes();
			this.setState({shouldUpdate: false});
		}
	}

	/**
	 * Update the state of the order notes
	 */
	updateOrderNotes() {
		var url = getUrl() + '/wp-json/planner/v1/orders/' + this.props.id + '/';

		axios.get(url, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then(res => this.setState({
			notes: res.data.notes,
		}))
		.catch(err => console.log(err));
	}

	syncOrderTravellers(e) {
		e.preventDefault();

		axios.put(getUrl() + '/wp-json/planner/v1/orders/' + this.props.id, {
			"refresh" : true,
			//"note" : "Travellers have been refreshed"
		}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then().catch(err => console.log(err))

		this.updateOrderNotes();
	}

	/**
	 * Update the system when the user selects a new confirmed order date.
	 * Also update pushbacks if the new date is after the original confirmed date.
	 * 
	 * @param {Event} event The event of the calendar component
	 */
	async dateChangeConfirmed(event) {
		let oldDateConfirmed = this.state.date_confirmed;

		if(event.target.value !== "" && formatDate(event.target.value) !== formatDate(oldDateConfirmed)) {
			this.setState({date_confirmed: event.target.value});

			// Update the system to save the change
			await axios.put(getUrl() + '/wp-json/wc/v3/orders/' + this.props.id, {
				"meta_data" : [
					{
						"key": "_cell_pack_confirmed_date",
						"value": event.target.value
					}
				],
			}, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}})
			.then()
			.catch(err => console.log(err));

			let isPushBackText = "";

			if(oldDateConfirmed) {
				// If the date for date confirmed is different to the original increment a pushback and save the data.
				if(new Date(this.state.date_confirmed) > new Date(oldDateConfirmed)) {
					let currentPushbacks = 0;

					// If there are no pushbacks set as 0 if there is, force to an int
					if(this.state.push_backs !== "") {
						currentPushbacks = Number(this.state.push_backs);
					}

					// Increment the pushbacks
					currentPushbacks = currentPushbacks + 1;

					isPushBackText = " (Pushback #" + currentPushbacks.toString() + ")";

					// Add the new pushback number to the order
					axios.put(getUrl() + '/wp-json/wc/v3/orders/' + this.props.id, {
						"meta_data" : [
							{
								"key": "_order_push_backs",
								"value": currentPushbacks
							},
						]
					}, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}});

					// Set the new pushback state
					this.setState({
						push_backs: currentPushbacks
					});
				}

				if(this.state.date_confirmed) {
					// Add note to the system about the change
					await axios.post(getUrl() + '/wp-json/wc/v3/orders/' + this.props.id + '/notes', {
						"note" : "Confirmed Date changed from " + formatDate(oldDateConfirmed) + " to " + formatDate(this.state.date_confirmed) + isPushBackText,
						"added_by_user": true
					}, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}});
				} else {
					// Add note to the system about the change
					await axios.post(getUrl() + '/wp-json/wc/v3/orders/' + this.props.id + '/notes', {
						"note" : "Confirmed Date " + formatDate(oldDateConfirmed) + " removed",
						"added_by_user": true
					}, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}});
				}
			} else {
				await axios.post(getUrl() + '/wp-json/wc/v3/orders/' + this.props.id + '/notes', {
					"note" : "Confirmed Date " + formatDate(this.state.date_confirmed) + " added",
					"added_by_user": true
				}, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}});
			}

			this.state.toast.current.show({ severity: 'success', summary: 'Job', detail: 'Date Changed updated.' });

			this.updateOrderNotes(); 
		}
	}

	/**
	 * If the stock is less than the quantity required for the order, add shortage badge with the quantity needed
	 * 
	 * @param {Object} rowData Data for the current row
	 * @returns {html} Adds shortage badge on material name if applicable
	 */
	isShortages() {
		const { bom } = this.state;
		let _bom = Object.values(bom)
		let isShortages = false;

		if(!this.state.isAllocated) {
			_bom.forEach(material => {
				if(material.stock < material.quantity) {
					isShortages = true;
				}
			});
		}

		return isShortages;
	}

	/**
	 * Create a note based off the users input on the note dialog popup
	 */
	async addNote() {
		if(this.state.note) {
			await axios.post(getUrl() + '/wp-json/wc/v3/orders/' + this.props.id + '/notes', {
				"note" : this.state.note,
				"added_by_user" : true
			}, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}})
			.then(this.setState({
				isLoaded: true,
				shouldUpdate: true,
				notes: null,
				note: '',
				noteDialog: false
			}))
			.catch(err => console.log(err));

			this.updateOrderNotes();

			this.state.toast.current.show({ severity: 'success', summary: 'Note', detail: 'Your note has been added to the order.' });
		} else {
			this.state.toast.current.show({ severity: 'error', summary: 'Note', detail: 'Please enter text in the note field.' }); 
		}
	}

	/**
	 * Check if the order is being edited by another user
	 * 
	 * @returns True or false depending on if the order is currently being edited by a different user
	 */
	isOrderLocked() {
		if(this.state.isLocked === false || this.props.currentLoggedInUser.id === this.state.isLocked.ID) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Check if the order is complete
	 * 
	 * @returns True or false depending on if the order is complete
	 */
	isOrderComplete() {
		if(this.state.status === 'completed' || this.state.status === 'cancelled') {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Checks the capaibilities of the user and locks the date complete if the user does not have the capabilities
	 * 
	 * @returns True or false depending on if the date completed is locked
	 */
	isDateCompleteLocked() {
		if(this.isOrderComplete() || !this.isOrderLocked() || this.props.capabilities.edit_jobs !== true) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Checks the capaibilities of the user and locks the status if the user does not have the capabilities
	 * 
	 * @returns True or false depending on if the status is locked
	 */
	isStatusLocked() {
		if(this.isOrderComplete() || !this.isOrderLocked() || this.props.capabilities.edit_jobs !== true) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Modify the title if the order is being edited or viewed by the user and if it's a Sales Order or Pro-Forma
	 * 
	 * @returns {string} The title of the order
	 */
	getOrderTitle() {
		let title;

		if(this.props.capabilities.view_jobs === true || this.props.capabilities.edit_jobs === true) {
			if(this.state.isLoaded) {
				title = 'Viewing Job: ';

				if(this.isOrderLocked() && this.state.order.status != 'cancelled' && this.state.order.status != 'completed' && (this.props.capabilities.edit_jobs === true)) {
					title = 'Editing Job: ';
				}

				if(this.state.order.wip.order_type == '11') {
					title += 'Pro-Forma #' + this.state.order_number;
				} else {
					title += 'Sales Order #' + this.state.order_number;
				}
			}
		} else {
			title = 'Access Denied';
		}

		return title;
	}

	/**
	 * When the stock is allocated to the job, update the status to no longer show as a shortage and save the allocation data
	 * 
	 * @param {Object} event The event for the checkbox
	 */
	async toggleAllocation(event) {
		let newStatus = 'shortage';

		// If the job is allocated force the status as inputted
		if(event.checked) {
			newStatus = 'inputted';

			// Add note to the system about the change
			await axios.post(getUrl() + '/wp-json/wc/v3/orders/' + this.props.id + '/notes', {
				"note" : "Order stock allocated",
				"added_by_user": true
			}, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}});
		}

		this.setState({
			isAllocated: event.checked,
			status: newStatus
		});

		await axios.put(getUrl() + '/wp-json/wc/v3/orders/' + this.props.id, {
			"meta_data" : [
				{
					"key": "_allocation",
					"value": event.checked
				}
			],
			"status" : newStatus
		}, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}})
		.then()
		.catch(err => console.log(err));

		this.state.toast.current.show({ severity: 'success', summary: 'Job', detail: 'Allocation updated.' });

		this.updateOrderNotes();
	}

	/**
	 * When the order status changes, save the new status and update the notes
	 * 
	 * @param {Object} event The event for the status change
	 */
	async changeStatus(event) {
		let oldStatus = this.state.status;

		let noDueDates = true;

		if(event.target.value === 'processing') {

			this.state.bom.forEach((e) => {
				if(e.due_date != '' && e.due_date != undefined) {
					noDueDates = false;
				} 
			})
		}

		if(noDueDates) {
			await axios.put(getUrl() + '/wp-json/planner/v1/orders/' + this.props.id, {
				"status" : event.target.value
			}, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}})
			.then()
			.catch(err => console.log(err));
	
			if(event.target.value === 'shortage' || event.target.value === 'inputted' || event.target.value === 'on-hold' || event.target.value === 'cancelled') {
				this.setState({
					isAllocated: false
				});
			} else {
				this.setState({
					isAllocated: true
				});
			}
	
			this.setState({
				status: event.target.value
			});
	
			this.state.toast.current.show({ severity: 'success', summary: 'Job', detail: 'Status updated.' });
	
			this.updateOrderNotes();
	
			if(event.target.value === 'in-production') {
				this.updateTravellers();
			}
	
			this.props.onStatusChange(oldStatus, event.target.value);		
		} else {
			this.state.toast.current.show({ severity: 'error', summary: 'Job', detail: 'Job awaiting stock and cannot go into production.' });
		}
	}

	/**
	 * Traveller Table
	 */

	/**
	 * Update the travellers
	 */
	async updateTravellers() {
		// Get traveller details for the order
		const [getTravelerDetails] = await Promise.all([
			axios.get(getUrl() + '/wp-json/planner/v1/travellers?order_id=' + this.props.id, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}} ),
		]);

		this.setState({
			travellers: getTravelerDetails.data
		});
	}

	/**
	 * Get the progress bar for the jobs completition percentage
	 * 
	 * @param {Object} rowData The data associated to the current row
	 * @returns The progress bar for the jobs completition
	 */
	getStatusBar(rowData) {
		return <span className={'badge bg-planner-' + rowData.status.toLowerCase()}>{rowData.status.replace('planner-', '')}</span>;
	}

	/**
	 * Get the total time the job should take to complete
	 * 
	 * @param {Object} rowData The data associated to the current row
	 * @returns Time formatting for the total time the job should take to complete
	 */
	getOrderTime(rowData) {
		return <span className="bg-dark badge text-white"><i className="pi pi-stopwatch me-1" style={{ fontSize: '0.65rem' }}></i>{getJobTimeFormat(rowData.total_target_time)}</span>
	}

	/**
	 * Get the current time spent on the job
	 * 
	 * @param {Object} rowData The data associated to the current row
	 * @returns Time formatting for the time spent on the job
	 */
	getCurrentTime(rowData) {
		let badgeClass = 'bg-success';

		if(rowData.total_recorded_time > rowData.total_target_time) {
			badgeClass = 'bg-warning';
		}

		if(rowData.order_time > (rowData.total_target_time + ((rowData.total_target_time / 100) * 5))) {
			badgeClass = 'bg-danger';
		}

		if(getJobTimeFormat(rowData.total_recorded_time) !== '00:00:0') {
			return <>
			<Tooltip autoHide={false} target=".fudge-ratio" />
			<span className={badgeClass + " badge text-white fudge-ratio"} 
				data-pr-tooltip={"Fudge Ratio: " + getJobTimeFormat(rowData.total_recorded_time * 1.0833)}
				data-pr-position="right"
				data-pr-at="right+5 center"
				data-pr-my="left center"><i className="pi pi-stopwatch me-1" style={{ fontSize: '0.65rem' }}></i>{getJobTimeFormat(rowData.total_recorded_time)}</span>
			</>
		}
	}

	/**
	 * Make the traveller id clickable
	 * 
	 * @param {Object} rowData The data associated to the current row
	 * @returns The link to the traveller
	 */
	getTravellerId(rowData) {
		return <Link to={"../traveller/" + rowData.id}>{rowData.traveller_number}</Link>
	}

	/**
	 * Make the product title clickable
	 * 
	 * @param {Object} rowData The data associated to the current row
	 * @returns The link to the product
	 */
	getProductSku(rowData) {
		if(this.props.capabilities.edit_products === true) {
			return <><Tooltip autoHide={false} target=".productDescription" /><Link className="productDescription" data-pr-tooltip={rowData.description}
			data-pr-position="right"
			data-pr-at="right+5 top"
			data-pr-my="left center-2" to={"../product/" + rowData.product_id}>{rowData.name}</Link></>
		} else {
			return rowData.name
		}
	}

	/**
	 * Products Table
	 */

	/**
	 * Get the total time for each product
	 * 
	 * @param {Object} rowData Data for the current row
	 * @returns The total time in hours for each product
	 */
	totalTime(rowData) {
		if(rowData.bom != null && rowData.time != null) {
			return <span className="bg-dark badge text-white"><i className="pi pi-stopwatch me-1" style={{ fontSize: '0.65rem' }}></i>{getJobTimeFormat(rowData.quantity * rowData.time)}</span>
		} else {
			return 'N/A';
		}
	}

	/**
	 * Get the product time in the correct format
	 * 
	 * @param {Object} rowData Data for the current row
	 * @returns The time for the product in minutes
	 */
	getTime(rowData) {
		if(rowData.bom != null && rowData.time != null) {
			return <span className="bg-dark badge text-white"><i className="pi pi-stopwatch me-1" style={{ fontSize: '0.65rem' }}></i>{getJobTimeFormat(rowData.time)}</span>
		} else {
			return 'N/A';
		}
	}

	/**
	 * Get the outstanding products.
	 * 
	 * @param {Object} rowData Data for the current row
	 * @returns The number of ordered products which have not been sent
	 */
	getOutstanding(rowData) {
		return rowData.quantity - rowData.dispatched;
	}

	/**
	 * Get the target time for the entire order.
	 * 
	 * @returns The time for the entire order
	 */
	getJobTime() {
		if(this.state.order.products) {
			let jobTime = 0;
			for(var i = 0; i < this.state.order.products.length; i++) {
				if(this.state.order.products[i].time !== 'N/A') {
					jobTime += Number(this.state.order.products[i].time) * Number(this.state.order.products[i].quantity);
				}
			}

			return jobTime;
		}
	}

	/**
	 * Generate the total target time for the product table footer
	 * 
	 * @returns HTML for the footer of the products table
	 */
	footer() {
		return (
			<div className="d-flex justify-content-between">
				{
					this.props.capabilities.edit_travellers && (
						<div>Subtotal Target Time: <span className="bg-dark badge text-white"><i className="pi pi-stopwatch me-1" style={{ fontSize: '0.65rem' }}></i>{getJobTimeFormat(this.getJobTime())}</span></div>
					)
				}
				<div>Job Value: {decode(this.state.jobTotal)}</div>
			</div>
		);
	}

	/**
	 * Bill of Materials Table
	 */

	/**
	 * If the stock is less than the quantity required for the order, add shortage badge with the quantity needed
	 * 
	 * @param {Object} rowData Data for the current row
	 * @returns {html} Adds shortage badge on material name if applicable
	 */
	getShortages(rowData) {
		if((rowData.shortage && (!this.state.isAllocated == true)) || rowData.id == null) {
			let shortageQty = rowData.shortage;

			if(rowData.id == null) {
				shortageQty = rowData.quantity;
			}

			if(rowData.id === null) {
				if(this.state.status !== 'cancelled' && this.state.status !== 'completed') {
					return <>
						<Tooltip autohide={false} target=".material-description" />
						<span data-pr-tooltip={rowData.description} className="material-description">{rowData.sku} <span className="badge bg-danger ms-2">Shortage {('(' + shortageQty + ')')}</span></span>
					</>
				} else {
					return <>
						<Tooltip autohide={false} target=".material-description" />
						<span data-pr-tooltip={rowData.description} className="material-description">{rowData.sku}</span>
					</>
				}
			} else {
				if(this.state.status !== 'cancelled' && this.state.status !== 'completed') {
					return <>
						<Tooltip autohide={false} target=".material-description" />
						<span data-pr-tooltip={rowData.description} className="material-description"><Link to={"/product/" + rowData.id}>{rowData.sku}</Link> <span className="badge bg-danger ms-2">Shortage {('(' + shortageQty + ')')}</span></span>
					</>
				} else {
					return <>
						<Tooltip autohide={false} target=".material-description" />
						<span data-pr-tooltip={rowData.description} className="material-description"><Link to={"/product/" + rowData.id}>{rowData.sku}</Link></span>
					</>
				}
			}
		} else {
			return <>
				<Tooltip autohide={false} target=".material-description" />
				<span data-pr-tooltip={rowData.description} className="material-description"><Link to={"/product/" + rowData.id}>{rowData.sku}</Link></span>
			</>
		}
	}

	getCategory(rowData) {
		return <span className="bg-primary badge">{rowData.category}</span>
	}

	/**
	 * Get the description for the BOM product
	 * 
	 * @param {Object} rowData Data for the current row
	 * @returns {html} The BOM description encoded
	 */
	getfreeStock(rowData) {
		return rowData.stock;
	}

	/**
	 * View for editing the due date when the row is being edited
	 * 
	 * @param {object} options The options for the current cell being edited
	 * @returns {html} Return the editing html for the due date
	 */
	materialEditor(options) {
		var dateObject = '';

		if(options.value) {
			var dateString = options.value.toString();
			var dateParts = dateString.split("/");
			dateObject = new Date(options.value);

			// Feels hacky but it works. https://stackoverflow.com/questions/33299687
			if(dateParts[1]) {
				dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
			}
		}

		return (
			<Calendar dateFormat="dd/mm/yy" showIcon value={dateObject} minDate={new Date()} onChange={(e) => options.editorCallback(e.value)} />
		)
	}

	/**
	 * View for editing the kitted when the row is being edited
	 * 
	 * @param {object} options The options for the current cell being edited
	 * @returns {html} Return the editing html for the kitted
	 */
	kittedEditor(options) {
		return (
			<InputNumber value={options.value} onChange={(e) => options.editorCallback(e.value)} max={options.rowData.quantity} min={0} />
		)
	}

	/**
	 * Get the cost price for the BOM item
	 * 
	 * @param {Object} rowData Data for the current row
	 * @returns {string} Get the cost price for the BOM item
	 */
	getCostPrice(rowData) {
		return decode(rowData.average_cost_price_display);
	}

	getKitted(rowData) {
		if(rowData.kitted && this.state.status !== 'ready-to-ship') {
			return rowData.kitted;
		} else {
			return '0';
		}
	}

	/**
	 * Get the materials due date. If the material is in stock, show due date as 'N/A'
	 * 
	 * @param {Object} rowData Data for the current row
	 * @returns {string} The due date for the material
	 */
	getDueDate(rowData) {
		if(rowData.due_date && this.state.status !== 'ready-to-ship') {
			return rowData.due_date;
		} else {
			return 'N/A';
		}
	}

	/**
	 * When user is finished editing update the state of the product
	 * 
	 * @param {Object} e The form event
	 */
	async onRowEditComplete(e) {
		let _bom = [...this.state.bom];
		let { newData, index } = e;

		newData.due_date = formatDate(newData.due_date);

		let oldDueDate = _bom[index].due_date;
		let oldKitted = _bom[index].kitted;
		_bom[index] = newData;

		this.setState({
			bom: _bom
		});

		let savedData = {};
		let kittedData = {};

		_bom.forEach(item => {
			if(item.due_date) {
				savedData[item.sku] = item.due_date;
			}

			if(item.kitted) {
				kittedData[item.sku] = item.kitted;
			}
		});

		// Update the order BOM
		axios.put(getUrl() + '/wp-json/wc/v3/orders/' + this.props.id, {
			"meta_data" : [
				{
					"key": "_order_bom_due_dates",
					"value": JSON.stringify(savedData)
				},
				{
					"key": "_order_bom_kitted",
					"value": JSON.stringify(kittedData)
				}
			]
		}, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}})
		.then()
		.catch(err => console.log(err));

		let note;
		let kittedNote;

		if(newData.due_date) {
			// Default the due date text to be set
			note = 'Due date for "' + newData.sku + '" set for "' + newData.due_date + '"';
		}

		if(oldDueDate) {
			if(newData.due_date == undefined) {
				note = newData.sku + ' due date deleted "' + oldDueDate + '"';
			} else {
				note = 'Due date for ' + newData.sku + ' changed from "' + oldDueDate + '" to "' + newData.due_date + '"';
			}
		}

		if(newData.kitted != oldKitted) {
			if(oldKitted > 0) {
				if(newData.kitted === null) {
					newData.kitted = 0;
				}

				kittedNote = 'Kitted for ' + newData.sku + ' changed from "' + oldKitted + '" to "' + newData.kitted + '"';
			} else {
				kittedNote = 'Kitted for "' + newData.sku + '" set for "' + newData.kitted + '"';
			}
		}

		if(note) {
			// Add the note for due dates to the job
			await axios.post(getUrl() + '/wp-json/wc/v3/orders/' + this.props.id + '/notes', {
				"note" : note,
				"added_by_user": true
			}, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}})
			.then()
			.catch(err => console.log(err));
		}

		if(kittedNote) {
			// Add the note for kitted to the job
			await axios.post(getUrl() + '/wp-json/wc/v3/orders/' + this.props.id + '/notes', {
				"note" : kittedNote,
				"added_by_user": true
			}, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}})
			.then()
			.catch(err => console.log(err));
		}

		this.state.toast.current.show({ severity: 'success', summary: 'Job', detail: 'BOM updated.' });

		this.updateOrderNotes();
	}

	/**
	 * Use CSS to hide the order due date button if the material is not a shortage
	 * 
	 * @param {Object} rowData Data for the current row
	 * @returns {string} The class names for the bom 
	 */
	bomClassName(rowData) {
		let className = '';

		if(rowData.quantity <= rowData.stock || this.state.isAllocated) {
			className += 'hide-edit-button';
		}

		return className;
	}

	/**
	 * Get the target time for the entire order.
	 * 
	 * @returns The time for the entire order
	 */
	getAverageCostPrice() {
		if(this.state.bom) {
			return decode((this.state.order.total_cost_price));
		}
	}

	bomFooter() {
		return <span className="d-flex justify-content-end">{'Total Materials Price: ' + this.getAverageCostPrice()}</span>;
	}

	getMaterialHeader() {
		return <><Tooltip autoHide={false} target=".pi-question-circle" /><span>Material <i className="pi pi-question-circle" style={{ fontSize: '0.75rem' }} 
			data-pr-tooltip="Non-kitted product codes shown and automatically drilled down to child equivalent"
			data-pr-position="right"
			data-pr-at="right+5 top"
			data-pr-my="left center-2"
		></i></span></>;
	}

	/**
	 * Notes Table
	 */

	/**
	 * Get the order note number
	 * 
	 * @param {Object} rowData Data for the current row
	 * @param {Object} props The properties of the row
	 * @returns {string} The current row number for the note
	 */
	rowNumber(rowData, props) {
		return this.state.notes.length - props.rowIndex;
	}

	/**
	 * Get the note dates
	 * 
	 * @param {Object} rowData Data for the current row
	 * @returns The correct formatting for notes
	 */
	noteDateTemplate(rowData) {
		return formatDate(rowData.comment_date, true);
	}

	/**
	 * Get the note content
	 * 
	 * @param {Object} rowData Data for the current row
	 * @returns The content for the note decoded
	 */
	noteContent(rowData) {
		return decode(rowData.comment_content);
	}

	/**
	 * Get product title and link
	 * 
	 * @param {Object} rowData Data for the current row
	 * @returns Get and link the product title
	 */
	getProductTitle(rowData) {
		return <>
			<Tooltip autoHide={false} target=".product-title-tooltip" />
			<Link className="product-title-tooltip" data-pr-tooltip={rowData.description} to={"/product/" + rowData.id}>{rowData.name}</Link>
		</>;
	}

	getProductCategory(rowData) {
		return <>
			<span className="bg-primary badge">{rowData.category}</span>
		</>
	}

	/**
	 * Get the note dates
	 * 
	 * @param {Object} rowData Data for the current row
	 * @returns The correct formatting for notes
	 */
	getMaterialTitle(rowData) {
		return <Link to={"/product/" + rowData.id}>{rowData.name}</Link>;
	}

	/**
	 * Get the current author of the note
	 * 
	 * @param {Object} rowData Data for the current row
	 * @returns {string} The author for the current note
	 */
	getNoteAuthor(rowData) {
		if(rowData.user_id != 0 && this.props.capabilities.edit_users) {
			return <Link to={'/employee/' + rowData.user_id}>{rowData.comment_author}</Link>
		} else {
			return rowData.comment_author;
		}
	}

	render() {
		const { isLoaded, toast, noteDialog, note, notes, order, status, date_confirmed, flexibility, bom, travellers, push_backs, company_name, planner } = this.state;

		let _bom = Object.values(bom);

		return (
			<>
				<Tooltip autoHide={false} target=".requested-date" />
				<Toast ref={toast} position="bottom-right" />
				<div className="edit-order-bar position-sticky top-0 p-3">
					<div className="container px-0 d-sm-flex justify-content-between align-items-center">
						<div className="d-flex align-items-center">
							<Button className="bg-primary p-2 me-3 d-flex align-items-center rounded d-xxl-none" onClick={e => {this.props.onSidebarOpen(true);}}>
								<i className="pi pi-bars text-white" style={{ fontSize: '1.25rem' }}></i>
							</Button>
							<div>
								<h1 className="mb-1 h3">{this.getOrderTitle()}</h1>
								{
									(this.props.capabilities.view_jobs || this.props.capabilities.edit_jobs) && isLoaded && (
										<div><strong>Created Date:</strong>&nbsp;{formatDate(order.date_created, true)}
										<span>{(this.state.priority) && (<span className={"badge bg-priority-" + this.state.priority + " ms-2"}>Priority: {this.state.priority}</span>)}</span></div>
									)
								}
								{
									!isLoaded && (
										<div>
											<i className="pi pi-spin pi-spinner" style={{ fontSize: '2.8rem' }}></i>
										</div>
									)
								}
							</div>
						</div>
						<div>
							<div className="d-flex align-items-center">
							{
								isLoaded && (this.props.capabilities.view_jobs || this.props.capabilities.edit_jobs) && (
									<>
										<div>
										{
											!this.isOrderLocked() && this.props.capabilities.edit_jobs && (
												<p className="d-inline mb-0 ms-3"><span className="badge bg-danger d-inline-flex align-items-center display-6"><i className="pi pi-lock me-2"></i> {this.state.isLocked.data.user_login} is currently editing this order</span></p>
											)
										}
										{
											this.state.status === 'completed' && (
												<p className="d-inline mb-0 ms-3"><span className="badge bg-success d-inline-flex align-items-center display-6"><i className="pi pi-calendar me-2"></i> Completed on {formatDate(this.state.order.date_completed)}</span></p>
											)
										}
										{
											this.state.status === 'cancelled' && (
												<p className="d-inline mb-0 ms-3"><span className="badge bg-danger d-inline-flex align-items-center display-6"><i className="pi pi-calendar me-2"></i> Cancelled on {formatDate(this.state.order.date_cancelled)}</span></p>
											)
										}
										</div>
										{
											this.isOrderLocked() && this.props.capabilities.edit_jobs && (
												<>
													<Button type="button" label="Add Note" icon="pi pi-comments" onClick={(e) => this.setState({noteDialog: true})} className="mt-2 mt-sm-0 ms-sm-3" disabled={!this.isOrderLocked()} />
												</>
											)
										}
									</>
								)
							}
							</div>
						</div>
					</div>
				</div>
				{
					isLoaded && (this.props.capabilities.view_jobs || this.props.capabilities.edit_jobs) && ( // https://stackoverflow.com/questions/70682832/
					<>
						<Dialog blockScroll={true} header="Add Note" visible={noteDialog} className="jobs-add-note-box" onHide={(e) => this.setState({noteDialog: false})}>
							<InputTextarea value={note} onChange={(e) => this.setState({note: e.target.value})} maxLength={500} rows={5} />
							<span>{(500 - note.length)+ " characters left"}</span><br />
							<Button type="button" label="Save Note" icon="pi pi-send" onClick={this.addNote} className="mt-4" />
						</Dialog>
						<form className="container px-4 px-xxl-0">
							<div className="row mt-5">
								<div className="col-12">
									<Fieldset legend={<>Sales Fields&nbsp;<Tooltip autoHide={false} target=".pi-info-circle" /><i className="pi pi-info-circle" style={{ fontSize: '0.9rem' }} data-pr-tooltip={"Last Synced: " + formatDate(this.state.order.last_synced, true, false, 'N/A')} data-pr-position="right" data-pr-at="right+5 center" data-pr-my="left center-2"></i></>}>
										<div className="container">
											<div className="row mt-0">
												<div className="form-group mb-2 col-12 col-md-6 col-lg-3">
													<label className="d-block"><strong>Order Status:</strong> {planner.order_status_to_display}</label>
												</div>
												<div className="form-group mb-2 col-12 col-md-6 col-lg-3">
													<label className="d-block"><strong>A/C:</strong> <Link to={'/jobs/sales-order?filter=' + planner.ac_number}>{planner.ac_number}</Link></label>
												</div>
												<div className="form-group mb-2 col-12 col-md-6 col-lg-3">
													<label className="d-block"><strong>Due Date:</strong> 
													{
														planner.order_due_date && (
															' ' + formatDate(planner.order_due_date)
														)
													}
													{
														!planner.order_due_date && (
															' N/A'
														)
													}
												</label>
												</div>
												<div className="form-group mb-2 col-12 col-md-6 col-lg-3">
													<label className="d-block">
														<strong>Job Flexibility:&nbsp;</strong>
														{
															flexibility && (
																flexibility
															)
														}
														{
															!flexibility && (
																'N/A'
															)
														}
													</label>
												</div>
											</div>
											<div className="row">
												<div className="form-group mb-2 col-12 col-md-6 col-lg-3">
													<label className="d-block"><strong>Customer:</strong> {
															company_name && (
																company_name
															)
														}
														{
															!company_name && (
																' N/A'
															)
														}</label>
												</div>
												<div className="form-group mb-2 col-12 col-md-6 col-lg-3">
													<label className="d-block"><strong>Customer Order #: </strong>
														{
															planner.cust_order_number && (
																planner.cust_order_number
															)
														}
														{
															!planner.cust_order_number && (
																' N/A'
															)
														}</label>
												</div>
												<div className="form-group mb-2 col-12 col-md-6 col-lg-3">
													<label className="d-block"><strong>A/C Manager:</strong> <Link to={'/jobs/sales-order?filter=' + planner.account_manager_to_display}>{planner.account_manager_to_display}</Link></label>
												</div>
												<div className="form-group mb-2 col-12 col-md-6 col-lg-3">
													<label className="d-block"><strong>Order Taken By:</strong> {planner.taken_by_to_display}</label>
												</div>
											</div>
										</div>
									</Fieldset>
								</div>
							</div>
							<div className="row mt-5">
								<div className="col-12">
									<Fieldset legend="Production Fields">
										<div className="container">
											<div className="row">
												<div className="form-group col-12 col-md-6 col-lg-4 mb-3 mb-md-0">
													<label className="d-block">
														<div className="d-flex justify-content-between align-items-center">
															<strong>Confirmed Date:</strong>
															{
																push_backs != null && (
																	<span className="badge bg-warning text-white">{push_backs} Pushback{(push_backs > 1 ? 's' : '')}</span>
																)
															}
														</div>
														<div className="mt-1">
															<Calendar
																value={date_confirmed}
																dateFormat="dd/mm/yy"
																onChange={this.dateChangeConfirmed}
																showIcon
																disabledDates={[
																	new Date('2024-08-23')
																]}
																disabledDays={[0, 6]}
																placeholder="Select a date..."
																disabled={this.isDateCompleteLocked()}
																minDate={new Date()}
															/>
														</div>
													</label>
												</div>
												<div className="form-group col-12 col-md-6 col-lg-8">
													<label className="d-block">
														<div className="d-flex justify-content-between">
															<strong>Job Status:</strong>
															{
																this.isShortages() && (
																	<span className="badge bg-danger">Shortage Detected</span>
																)
															}
														</div>
														<div className="mt-1">
															<Dropdown
																options={this.props.availableStatuses}
																value={status} 
																optionLabel="name" 
																optionDisabled={(option) => option.name === 'Completed' || option.name === 'Cancelled' || option.name === 'On Hold'}
																placeholder="Job Status" 
																optionValue="slug" 
																className="me-2" 
																onChange={this.changeStatus.bind(this)}
																disabled={this.isStatusLocked()}
															/>
														</div>
													</label>
												</div>
											</div>
										</div>
									</Fieldset>
								</div>
							</div>
							{
								(travellers.length > 0 || this.state.status === 'processing') && this.props.capabilities.edit_travellers === true && (
									<>
										<div className="row mt-5">
											<div className="col-12">
												<Fieldset legend={<>Travellers&nbsp;<Tooltip autoHide={false} target=".pi-info-circle" /><i className="pi pi-info-circle" style={{ fontSize: '0.9rem' }} data-pr-tooltip={"Last Synced: " + formatDate(this.state.order.travellers_last_synced, true, false, 'N/A')} data-pr-position="right" data-pr-at="right+5 center" data-pr-my="left center-2"></i></>}>
													<div className="container">
														<div className="row mt-0">
															<div className="col-12" style={{marginTop: '-1.7rem'}}>
																<div className="d-flex justify-content-end">
																	<button className="p-button p-component mb-3 ms-2" onClick={(e) => this.syncOrderTravellers(e)}><i className="pi pi-history me-1"></i> Sync</button>
																</div>
																<DataTable value={travellers} tableStyle={{ minWidth: '50rem' }}>
																	<Column field="traveller_number" header="Traveller #" body={this.getTravellerId}></Column>
																	<Column field="product.sku" header="Product Code" body={this.getProductSku}></Column>
																	<Column field="product_qty" header="Quantity"></Column>
																	<Column field="product.time" header="Total Target Time" body={this.getOrderTime}></Column>
																	<Column field="product.currentTime" header="Total Recorded Time" body={this.getCurrentTime}></Column>
																	<Column field="status" header="Status" body={this.getStatusBar}></Column>
																</DataTable>
															</div>
														</div>
													</div>
												</Fieldset>
											</div>
										</div>
									</>
								)
							}
							{
								order.products !== null && order.products.length > 0 && this.props.capabilities.edit_products === true && (
									<>
										<div className="row mt-5">
											<div className="col-12">
												<Fieldset legend="Products">
													<div className="container">
														<div className="row mt-0">
															<DataTable value={order.products} tableStyle={{ minWidth: '50rem' }} footer={this.footer(this)}>
																<Column field="line_item" header="Line Item #"></Column>
																<Column field="name" header="Product Code" body={this.getProductTitle}></Column>
																<Column field="category" header="Category" body={this.getProductCategory}></Column>
																<Column field="quantity" header="Ordered" alignHeader={'right'} bodyStyle={{ textAlign: 'right' }}></Column>
																<Column field="dispatched" header="Despatched" alignHeader={'right'} bodyStyle={{ textAlign: 'right' }}></Column>
																<Column field="outstanding" header="Outstanding" alignHeader={'right'} bodyStyle={{ textAlign: 'right' }} body={this.getOutstanding}></Column>
																<Column field="allocated" header="Allocated" alignHeader={'right'} bodyStyle={{ textAlign: 'right' }}></Column>
																{
																	this.props.capabilities.edit_travellers && (
																			<Column field="time" header="Target Time" body={this.getTime}></Column>
																	)
																}
																{
																	this.props.capabilities.edit_travellers && (
																			<Column body={this.totalTime} header="Total Time"></Column>		
																	)
																}
															</DataTable>
														</div>
													</div>
												</Fieldset>
											</div>
										</div>
									</>
								)
							}
							{
								_bom.length > 0 && this.props.capabilities.edit_products === true && (
									<>
										<div className="row mt-5">
											<div className="col-12">
												<Fieldset legend="Bill of Materials (BOM)">
													<div className="container">
														<div className="row mt-0">
															<DataTable value={_bom} tableStyle={{ minWidth: '50rem' }} editMode="row" footer={this.bomFooter(this)} onRowEditComplete={this.onRowEditComplete}>
																<Column field="sku" header={this.getMaterialHeader} body={this.getShortages}></Column>
																<Column field="category" header="Category" body={this.getCategory}></Column>
																<Column field="quantity" header="Quantity" bodyStyle={{ textAlign: 'right' }} alignHeader={'right'}></Column>
																{
																	(this.state.status != 'completed' && this.state.status != 'cancelled') && (
																		<Column field="stock" header="Free Stock" body={this.getfreeStock} alignHeader={'right'} bodyStyle={{ textAlign: 'right' }}></Column>
																	)
																}
																<Column field="kitted" header="Kitted" editor={this.kittedEditor.bind(this)} body={this.getKitted.bind(this)} alignHeader={'right'} bodyStyle={{ textAlign: 'right' }}></Column>
																<Column field="average_cost_price" header="Cost Price" body={this.getCostPrice.bind(this)} alignHeader={'right'} bodyStyle={{ textAlign: 'right' }}></Column>
																<Column field="due_date" header="Due Date" editor={this.materialEditor} body={this.getDueDate.bind(this)}></Column>
																{
																	this.isOrderLocked() && status != 'completed' && status != 'cancelled' && status !== 'ready-to-ship' && this.props.capabilities.edit_due_dates && ( 
																		<Column rowEditor headerStyle={{ width: '5%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'right' }}></Column>
																	)
																}
															</DataTable>
														</div>
													</div>
												</Fieldset>
											</div>
										</div>
									</>
								)
							}
							{
								notes && (
									<>
										<div className="row mt-5 mb-5">
											<div className="col-12">
												<Fieldset legend="Notes">
													<div className="container notes_table">
														<div className="row mt-0">
															<DataTable value={notes} tableStyle={{ minWidth: '50rem' }} paginator rows={10} sortOrder={-1} sortField="id">
																<Column header="Note #" field="id" body={this.rowNumber.bind(this)} headerStyle={{ width: '7rem' }}></Column>
																<Column field="date_created" header="Date" body={this.noteDateTemplate} headerStyle={{ width: '12rem' }}></Column>
																<Column field="note" header="Note" body={this.noteContent}></Column>
																<Column field="author" header="Author" body={this.getNoteAuthor} headerStyle={{ width: '7rem' }}></Column>
															</DataTable>
														</div>
													</div>
												</Fieldset>
											</div>
										</div>
									</>
								)
							}
						</form> 
					</>
					)
				}
			</>
		);
	}
}
export default EditOrderForm;