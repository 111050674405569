import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import { getUrl, getJobTimeBadge } from '../../planner/planner';
import { Link } from 'react-router-dom';

export default function InProduction(props) {
		const [orders, setOrders] = useState([]);

		function getOrders() {
			axios.get(getUrl() + '/wp-json/wc/v3/orders?jobs=in-production', { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then(res => setOrders(res.data)).catch(err => console.log(err));
		}

		useEffect(() => {
			getOrders();
		}, []);

		function getEditButton() {
			let icon = <i className="pi pi-pencil me-1" style={{ fontSize: '0.8rem' }}></i>;
			let text = 'Edit';

			return (<Link to={"#"} className="btn btn-primary">{icon} {text}</Link>);
		}

		function getJobtime(rowData) {
			if(rowData.products) {
				let jobTime = 0;

				for(var i = 0; i < rowData.products.length; i++) {
					jobTime += Number(rowData.products[i].time) * Number(rowData.products[i].quantity);
				}

				return <span>{getJobTimeBadge(jobTime)}</span>;
			}
		}

		return (
			<>
				<h4 className="mb-3">In Production Orders</h4>
				<DataTable value={orders} tableStyle={{ minWidth: '50rem' }} paginator rows={4}>
					<Column field="id" header="Sales Order"></Column>
					<Column field="billing.company" header="Company Name"></Column>
					<Column field="job_time" header="Target Time" body={getJobtime}></Column>
					<Column field="edit" header="" body={getEditButton} style={{ width: '7rem' }}></Column>
				</DataTable>
			</>
		);
}