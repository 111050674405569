import React from "react";
import EditSuggestionForm from './edit-suggestion-form';
import { useLocation } from 'react-router-dom';

export default function SuggestionWrapper(props) {
	const paths = useLocation().pathname.split('/');

	function sidebarOpened() {
		props.updateSidebarOpened(true);
	}

	return (
		<EditSuggestionForm currentLoggedInUser={props.currentLoggedInUser} id={paths[paths.length - 1]} capabilities={props.capabilities} onSidebarOpen={sidebarOpened} />
	)
}