import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import axios from 'axios';
import { getUrl } from '../../planner/planner';

export default function LowStock(props) {
		const [LowStockproducts, setlowStockProducts] = useState([]);
		const [products, setProducts] = useState([]);
		const [selectedLowStockOption, setLowStockOption] = useState(null);
		const lowStockOptions = [25, 50, 100, 250, 500, 1000];

		function getProducts() {
			var url = getUrl() + '/wp-json/wc/v3/products';

			if(props.category) {
				url += '?category=' + props.category;
			}

			axios.get(url, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then(res => setProducts(res.data)).catch(err => console.log(err));
		}

		function updateLowStockProducts() {
			const tableRows = [];
			let lowStock;

			if(selectedLowStockOption === null) {
				lowStock = 100;
			} else {
				lowStock = selectedLowStockOption;
			}

			products.forEach(product => {
				if(product.stock_quantity < lowStock && product.stock_quantity !== null) {
					tableRows.push({
						sku: product.sku,
						stock: product.stock_quantity,
					})
				}
			});

			setlowStockProducts(tableRows);
		}

    useEffect(() => {
			// Avoid continously fetching data from REST
			if(products.length === 0) {
				if(localStorage.getItem('lowStock' + props.category)) {
					setLowStockOption(JSON.parse(localStorage.getItem('lowStock' + props.category)));
				} else {
					setLowStockOption(100);
				}

				getProducts();
			}

			updateLowStockProducts();
    }, [selectedLowStockOption, products]);

		function saveLowStockOption(e) {
			setLowStockOption(e);
			localStorage.setItem('lowStock' + props.category, e);
		}

		return (
			<>
				<div className="d-flex align-items-end justify-content-between">
					<h4 className="mb-0">{props.title}</h4>
				</div>
				<DataTable value={LowStockproducts} className="mt-3" paginator rows={10}>
					<Column field="sku" header="Product Code"></Column>
					<Column field="stock" header="Free Stock"></Column> 
				</DataTable>
				<div className="d-flex align-items-center justify-content-end mt-3">
					<div>
						<strong className="me-2">Set Low Stock: </strong>
					</div>
					<Dropdown value={selectedLowStockOption} onChange={(e) => saveLowStockOption(e.value)} options={lowStockOptions}
												placeholder="Set Low Stock Level" />
				</div>
			</>
		);
}