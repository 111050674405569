import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Link } from 'react-router-dom';

import { Column } from 'primereact/column';
import axios from 'axios';
import { getUrl } from '../../planner/planner';

export default function LowStock(props) {
		const [missingTimingproducts, setMissingTimingproducts] = useState([]);
		const [products, setProducts] = useState([]);

		function getProducts() {
			var url = getUrl() + '/wp-json/wc/v3/products?per_page=9999';

			if(props.category) {
				url += '&category=' + props.category;
			}

			axios.get(url, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then(res => setProducts(res.data)).catch(err => console.log(err));
		}

		function updateNoTimingProducts() {
			const tableRows = [];

			products.forEach(product => {
				if(product.processes.length === 0) {
					tableRows.push({
						id: product.id,
						sku: product.sku,
						isLocked: product.locked,
						manufacturer: product.manufacturer,
						description: product.name,
						stock: product.stock_quantity,
					})
				}
			});

			setMissingTimingproducts(tableRows);
		}

    useEffect(() => {
			// Avoid continously fetching data from REST
			if(products.length === 0) {
				getProducts();
			}

			updateNoTimingProducts();
    }, [products]);

	/**
	 * Create a button which links to the individual product page
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns A button linking to view the product
	 */
	function editProductButton(rowData) {
		const url = "/product/" + rowData['id'];

		return (<div className="d-block text-end"><Link to={url} className="btn btn-primary"><i className="pi pi-pencil me-2" style={{ fontSize: '0.8rem' }}></i>Edit</Link></div>);
  }

	return (
		<>
			<div className="d-flex align-items-end justify-content-between">
				<h4 className="mb-0">{props.title}</h4>
			</div>
			<DataTable value={missingTimingproducts}  tableStyle={{ minWidth: '40rem' }} className="mt-3" paginator rows={4}>
				<Column field="sku" header="Product Code"></Column>
				<Column field="description" header="Description"></Column>
				<Column field="stock" header="Free Stock"></Column>
				<Column field="manufacturer" header="Manufacturer"></Column>
				<Column body={editProductButton} header="" />
			</DataTable>
		</>
	);
}