import React from "react";
import EditOrderForm from './edit-order-form';
import { useLocation } from 'react-router-dom';

export default function OrderWrapper(props) {
	const paths = useLocation().pathname.split('/');

	function statusChanged(oldStatus, newStatus) {
		props.updateAppStatusCount(oldStatus, newStatus);
	}

	function sidebarOpened() {
		props.updateSidebarOpened(true);
	}

	return (
		<>
			<EditOrderForm currentLoggedInUser={props.currentLoggedInUser} title="Sales Orders - Planner" id={paths[paths.length - 1]} capabilities={props.capabilities} availableStatuses={props.availableStatuses} onStatusChange={statusChanged} onSidebarOpen={sidebarOpened} />
		</>
	)
}