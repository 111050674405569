import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import { getUrl, formatTrafficDate, formatDate } from '../../planner/planner';
import { Link } from 'react-router-dom';

export default function InProduction() {
		const [orders, setOrders] = useState([]);

		function getOrders() {
			axios.get(getUrl() + '/wp-json/wc/v3/orders?jobs=in-production', { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then(res => setOrders(res.data)).catch(err => console.log(err));
		}

		useEffect(() => {
			getOrders();
		}, []);

		function getEditButton() {
			let icon = <i className="pi pi-pencil me-1" style={{ fontSize: '0.8rem' }}></i>;
			let text = 'Edit';

			return (<Link to={"#"} className="btn btn-primary">{icon} {text}</Link>);
		}

		function getRequestedDate(rowData) {
			return(
				<div>
					<div>{formatDate(rowData.wip.customer_requested_date)}</div>
				</div>
			);
		}

		function getConfirmedDate(rowData) {
			return(
				<div>
					<div>{formatTrafficDate(rowData.wip.cell_pack_confirmed_date)}</div>
				</div>
			);
		}

		return (
			<>
				<h4 className="mb-3">Priority Orders</h4>
				<DataTable value={orders} tableStyle={{ minWidth: '30rem' }} paginator rows={4}>
					<Column field="id" header="Sales Order"></Column>
					<Column field="billing.company" header="Company Name"></Column>
					<Column field="wip.customer_requested_date" body={getRequestedDate} header="Requested Date"></Column>
					<Column field="wip.cell_pack_confirmed_date" body={getConfirmedDate} header="Confirmed Date"></Column>
					<Column field="edit" header="" body={getEditButton} style={{ width: '7rem' }}></Column>
				</DataTable>
			</>
		);
}