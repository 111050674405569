import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import { Card } from 'primereact/card';

import axios from 'axios';
import { getUrl } from '../../planner/planner';

export default function ProductStock() {

	const [accountManagers, setAccountManagers] = useState([]);
	const [productionOperators, setProductionOperators] = useState([]);
	const [productionManagers, setProductionManagers] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [chartData, setChartData] = useState({});
	const [chartOptions, setChartOptions] = useState({});

		function getStats() {
			if(!loaded) {
				axios.get(getUrl() + '/wp-json/planner/v1/employees?role=account_manager', { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then(res => setAccountManagers(res.data.length), setLoaded(true)).catch(err => console.log(err));
				axios.get(getUrl() + '/wp-json/planner/v1/employees?role=production_operator', { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then(res => setProductionOperators(res.data.length), setLoaded(true)).catch(err => console.log(err));
				axios.get(getUrl() + '/wp-json/planner/v1/employees?role=production_manager', { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then(res => setProductionManagers(res.data.length), setLoaded(true)).catch(err => console.log(err));
			}
		}

		getStats();

    useEffect(() => {

        const data = {
            labels: ["Account Managers", "Production Operators", "Production Managers"],
            datasets: [
                {
                    data: [accountManagers, productionOperators, productionManagers],
                    backgroundColor: [
											"#d8d8d8", 
											"#555555", 
											"#000000", 
                    ]
                }
            ]
        };

        const options = {
					plugins: {
						legend: {
							labels: {
									usePointStyle: true,
							},
							position: 'bottom'
						},
						title: {
							display: true,
							text: 'Employees',
							font: {
								size: 22,
								weight: 400
							}
						}
					}
        };

        setChartData(data);
        setChartOptions(options);
    }, [accountManagers, productionOperators, productionManagers]);

    return (
			<Card className="order-status-cart">
				<Chart type="doughnut" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
			</Card> 
    )
}