import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';

import { Column } from 'primereact/column';
import axios from 'axios';
import { getUrl } from '../../planner/planner';

export default function AccountManagers() {
	const [employees, setEmployees] = useState([]);

	function getProducts() {
		axios.get(getUrl() + '/wp-json/planner/v1/employees?role=account_manager', { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then(res => setEmployees(res.data) ).catch(err => console.log(err));
	}

	getProducts();

	return (
		<>
			<h4 className="mb-3">Account Manager Statistics</h4>
			<DataTable dataKey="id" size="small" sortOrder={1} sortField="id" value={employees} tableStyle={{ minWidth: '30rem' }}>
				<Column key="id" field="id" header="Employee #" sortable={true} style={{maxWidth: '6rem'}} />
				<Column key="name" field="name" header="Name" sortable={true} />
			</DataTable>
		</>
	);
}