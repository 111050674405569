import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid';
import momentPlugin from '@fullcalendar/moment';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import axios from 'axios';
import { getUrl, getJobTimeFormat } from '../planner/planner';
import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';

export default function App(props) {
	const [data, setData] = useState([]);
	const [orders, setOrders] = useState([]);
	const [editingEvent, setEditingEvent] = useState(null);
	const [availableSeconds, setAvailableSeconds] = useState(0);
	const [weeklyOrderData, setWeeklyOrderData] = useState([]);
	const [confirmedDateOrderDate, setConfirmedDateOrderData] = useState([]);
	const [weeklyEmployeeData, setWeeklyEmployeeData] = useState([]);
	const [orderSeconds, setOrderSeconds] = useState(0);
	const [confirmedSeconds, setConfirmedSeconds] = useState(0);
	const [employeeSeconds, setEmployeeSeconds] = useState(0);
	const [onDropEvent, setOnDropEvent] = useState(null);
	const [editDialog, setEditDialog] = useState(false);
	const [operatorDialog, setOpperatorDialog] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [operatorNumber, setOpperatorNumber] = useState(1);
	const [operatorTotal, setOperatorTotal] = useState(1);

  useEffect(() => {
		axios.get(getUrl() + '/wp-json/planner/v1/bookings?category_id=' + 97, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then(res => {
			setOrders(res.data.bookings);
			setAvailableSeconds(res.data.employees_basic_total);
			setWeeklyOrderData(res.data.weekly_total_traveller_bookings);
			setConfirmedDateOrderData(res.data.weekly_total_target_times);
			setWeeklyEmployeeData(res.data.weekly_total_employee_bookings);

			setData(res.data.bookable_travellers);
			setIsLoaded(true);
		}).catch(err => {
			console.log(err)
		});

		const containerEl = document.querySelector("#events");

		let draggable = new Draggable(containerEl, {
			itemSelector: ".event",
			eventData: (eventEl) => {
				return {
					id: eventEl.getAttribute('orderid'),
					title: eventEl.getAttribute('ordernumber'),
					duration: eventEl.getAttribute('eventduration'),
					backgroundColor: eventEl.getAttribute('eventcolor'),
					extendedProps: {
						companyDisplayName: eventEl.getAttribute('companydisplayname'),
						orderJobTime: eventEl.getAttribute('orderjobtime'),
					},
					borderColor: eventEl.getAttribute('eventcolor'),
					textColor: eventEl.getAttribute('eventtextcolor')
				};
			}
		});

		return () => {
			draggable.destroy()
		};
  }, [orderSeconds]);

	function onDrop(info) {
		setOpperatorDialog(true);
		setOnDropEvent(info);
	}

	function onResize(info) {
		setOrderSeconds((prevCount) => prevCount + Moment(info.event.end).diff(info.oldEvent.end, 'minutes'));

		axios.put(getUrl() + '/wp-json/planner/v1/bookings/' + info.event.id, {
			date_to: Moment(info.event.end).format("YYYY-MM-DD HH:mm")
		}, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}}).then().catch(err => console.log(err));
	}

	function onDrag(info) {
		axios.put(getUrl() + '/wp-json/planner/v1/bookings/' + info.event.id, {
			date_to: Moment(info.event.end).format("YYYY-MM-DD HH:mm"),
			date_from: Moment(info.event.start).format("YYYY-MM-DD HH:mm")
		}, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}}).then().catch(err => console.log(err));
	}

	let badgeClasses = 'bg-success';

	if((availableSeconds - employeeSeconds) < orderSeconds) {
		badgeClasses = 'bg-danger';
	}

	function saveEvent() {
		let startTime = Moment(onDropEvent.date).format("YYYY-MM-DD HH:mm");
		let endTime = Moment(onDropEvent.date).add(1, 'hours').format("YYYY-MM-DD HH:mm");
		let eventSeconds = orderSeconds;

		axios.post(getUrl() + '/wp-json/planner/v1/bookings', {
			author_id: 1,
			category_id: 97,
			date_from: startTime,
			date_to: endTime,
			operator_total: operatorNumber,
			user_id: 1,
			traveller_id: onDropEvent.draggedEl.getAttribute('eventtravelerid'),
		}, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}}).then().catch(err => console.log(err));

		let newEventSeconds = eventSeconds + (3600 * operatorNumber);
		setOrderSeconds(newEventSeconds);
		setOpperatorDialog(false);
		setOpperatorNumber(1);
	}

	function editBooking(event) {
		setEditingEvent(event);
		setEditDialog(true);
		setOperatorTotal(event.event.extendedProps.operatorTotal);
	}

	function editEvent() {
		axios.put(getUrl() + '/wp-json/planner/v1/bookings/' + editingEvent.event.id, {
			operator_total: operatorNumber
		}, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}}).then().catch(err => console.log(err));

		setEditDialog(false)
	}

	function deleteBooking() {
		let newOrders = [];

		orders.forEach(function(event) {
			if(event.id != editingEvent.event.id) {
				newOrders.push(event);
			} else {
				setOrderSeconds((prevCount) => prevCount + Moment(event.start).diff(event.end, 'minutes'));
			}
		});

		setOrders(newOrders);

		axios.delete(getUrl() + '/wp-json/planner/v1/bookings/' + editingEvent.event.id, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}}).then().catch(err => console.log(err));

		setEditingEvent(null);
		setEditDialog(false);
	}

	let loadingClass = 'isLoading';

	if(isLoaded) {
		loadingClass = 'notLoading';
	}

	return (<>
		<Dialog blockScroll={true} header="Edit Booking" visible={editDialog} style={{ width: '25vw' }} draggable={false} onHide={(e) => setEditDialog(false)}>
			<div className="d-flex">
				<div className="flex-grow-1">
					<strong>Operators</strong>
					<InputNumber value={operatorTotal} onValueChange={(e) => setOpperatorNumber(e.value)} className="d-block operators-input" />
				</div>
			</div>
			<div className="d-flex mt-3">
				<Button type="button" label="Save Booking" icon="pi pi-send" onClick={editEvent} />
				<Button type="button" label="Delete Booking" icon="pi pi-trash" className="bg-danger ms-3" onClick={deleteBooking} />
			</div>
		</Dialog>

		<Dialog blockScroll={true} header="Add Booking" visible={operatorDialog} style={{ width: '25vw' }} closable={false} draggable={false}>
			<div className="d-flex">
				<div className="flex-grow-1">
					<strong>Operators</strong>
					<InputNumber value={operatorNumber} onValueChange={(e) => setOpperatorNumber(e.value)} className="d-block operators-input" />
				</div>
				<div className="d-flex">
					<div className="align-self-end">
						<Button type="button" label="Save Booking" icon="pi pi-send" className="ms-3" onClick={saveEvent} />
					</div>
				</div>
			</div>
		</Dialog>
		<div className="edit-order-bar position-sticky top-0 py-3 mb-5" >
			<div className="mx-5 px-0 d-flex justify-content-between align-items-center w-100" style={{maxWidth: "100%"}}>
				<div className="d-flex justify-content-between w-100">
					<div className="d-flex align-items-center">
						<div>
							<Button className="bg-primary p-2 me-3 d-flex align-items-center rounded d-xxl-none" onClick={e => {props.updateSidebarOpened(true);}}>
								<i className="pi pi-bars text-white" style={{ fontSize: '1.25rem' }}></i>
							</Button>
						</div>
						<h1 className="mb-0 h3">Job Bookings</h1>
					</div>
					<div className="text-end align-items-center d-flex">
						<div>
							<div className="mb-1">
								<strong>Total Employee Hours: </strong><div className={"badge " + badgeClasses + " text-white"}><i className="pi pi-stopwatch me-1" style={{ fontSize: '0.65rem' }} ></i>{getJobTimeFormat(( availableSeconds - employeeSeconds ))}</div>
							</div>
							<div>
								<strong>Available Employee Hours: </strong><div className={"badge " + badgeClasses + " text-white"}><i className="pi pi-stopwatch me-1" style={{ fontSize: '0.65rem' }} ></i>{getJobTimeFormat(( availableSeconds - employeeSeconds ) - orderSeconds)}</div>
							</div>
						</div>
						<div className="ms-2">
							<div className="mb-1">
								<strong>Confirmed Hours: </strong><div className={"badge " + badgeClasses + " text-white"}><i className="pi pi-stopwatch me-1" style={{ fontSize: '0.65rem' }} ></i>{getJobTimeFormat(confirmedSeconds)}</div>
							</div>
							<div>
								<strong>Booked Hours: </strong><div className={"badge " + badgeClasses + " text-white"}><i className="pi pi-stopwatch me-1" style={{ fontSize: '0.65rem' }} ></i>{getJobTimeFormat(orderSeconds)}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{
			!isLoaded && (
				<div class="mt-5">
					<div className={"mt-5 mx-5 mb-3"} style={{ position: 'absolute', top: '17.5rem', zIndex: '99999', left: '45%' }}>
						<i className="pi pi-spin pi-spinner" style={{ fontSize: '6rem' }}></i>
					</div>
				</div>
			)
		}
    <div className={"container-fluid px-5 mb-3 " + loadingClass}>
		<div className="row">
			<div className="col-12 col-md-10">
				<FullCalendar
					plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, momentPlugin]}
					initialView="timeGridWeek"
					events={orders}
					hiddenDays={[0]}
					editable
					drop={onDrop}
					slotMinTime={"08:00"}
					slotMaxTime={"17:00"}
					eventDurationEditable={true}
					eventConstraint={'businessHours'}
					droppable
					contentHeight="auto"
					eventDrop={onDrag}
					eventResize={onResize}
					nextDayThreshold={'09:00'}
					weekNumbers={true}
					weekText="#"
					weekNumberFormat={
						{ week: 'narrow' }
					}
					slotLabelFormat={{
						hour: 'numeric',
						minute: '2-digit',
						omitZeroMinute: false,
						meridiem: false,
						hour12: false
					}}
					views={{
						timeGridWeek: { // name of view
							titleFormat: {
								month: 'long',
								day: '2-digit',
								year: 'numeric'
							},
							dayHeaderContent: (e) => {
								return <div>{Moment(e.date).format('dddd Do')}</div>
							}
						}
					}}
					businessHours={{
						daysOfWeek: [1, 2, 3, 4, 5, 6],
						startTime: '08:00',
						endTime: '17:00',
					}}
					allDayText=""
					datesSet={(arg) => {
						let calendarStartTime = Moment(arg.view.activeStart).format('YYYY-MM-DD');
						let orderSeconds = 0;
						let employeeSeconds = 0;
						let confirmedDateSeconds = 0;

						if(weeklyOrderData != null && weeklyOrderData.length != 0 && weeklyOrderData[calendarStartTime]) {
							orderSeconds = weeklyOrderData[calendarStartTime].secondsWeek;
						}

						if(weeklyEmployeeData != null && weeklyEmployeeData.length != 0 && weeklyEmployeeData[calendarStartTime]) {
							employeeSeconds = weeklyEmployeeData[calendarStartTime].secondsWeek.decrement;
						}

						if(confirmedDateOrderDate != null && confirmedDateOrderDate.length != 0 && confirmedDateOrderDate[calendarStartTime]) {
							confirmedDateSeconds = confirmedDateOrderDate[calendarStartTime].secondsWeek;
						}

						setOrderSeconds(orderSeconds);
						setEmployeeSeconds(employeeSeconds);
						setConfirmedSeconds(confirmedDateSeconds);
					}}
					slotDuration={'00:10:00'}
					firstDay={1}
					nowIndicator={true}
					eventContent={(event) => {
						let pencilHoverClass = 'dark-hover';

						if(event.event.textColor === '#000000') {
							pencilHoverClass = 'light-hover';
						}

						let timerColorClass = 'bg-dark';

						if(event.event.extendedProps.orderJobTime === '00:00:00') {
							timerColorClass = 'bg-danger';
						}

						return(<>
							{
								event.isDraggable !== false && (
									<div className="d-flex align-items-center mx-1">
										<div onClick={(e) => editBooking(event)} className={"delete-button me-1 " + pencilHoverClass}><i className="pi pi-pencil" style={{ color: event.event.textColor, fontSize: '0.55rem' }}></i></div>
										<div>{event.event.title}</div>
									</div>
								)
							}
							<div className="mx-1">
								{
									event.isDraggable === false && (
										<div>{event.event.title}</div>
									)
								}
								<div>{event.timeText}</div>
								{
									event.event.extendedProps.productSku && event.event.extendedProps.productQty && (
										<div className="d-flex" style={{ fontSize: '0.7rem' }}>
											{event.event.extendedProps.productSku} <div className="mx-1">x</div> <div><span className="badge bg-primary">{event.event.extendedProps.productQty}</span></div>
										</div>
									)
								}
								{
									event.event.extendedProps.companyDisplayName && event.event.extendedProps.orderNumber && (
										<small className="d-block">{event.event.extendedProps.companyDisplayName} - #{event.event.extendedProps.orderNumber}</small>
									)
								}
								{
									!event.event.extendedProps.orderNumber && (
										<small className="d-block">{event.event.extendedProps.companyDisplayName}</small>
									)
								}
								{
									(event.event.extendedProps.travellerTargetTime != null) && (
										<div className="flex align-items-center">
											<span className={timerColorClass + " badge text-white"}><i className="pi pi-stopwatch me-1" style={{ fontSize: '0.65rem' }} ></i>{event.event.extendedProps.travellerTargetTime}</span>&nbsp;<span><i className="pi pi-user" style={{ fontSize: '0.7rem' }}></i> <small>x {event.event.extendedProps.operatorTotal}</small></span>
										</div>
									)
								}
							</div>
						</>)
					}}
				/>
			</div>
			<div className="col-12 col-md-2 mt-3 mt-md-0">
				<ul id="events" className="d-flex p-0 flex-wrap">
					{
						data != null && data.length != 0 && (
							data.map(item => {
								let timerColorClass = 'bg-dark';

								if(item.extendedProps.orderJobTime === '00:00:00') {
									timerColorClass = 'bg-danger';
								}

								return (<li key={item.extendedProps.orderId} orderid={item.extendedProps.orderId} ordernumber={item.title} companydisplayname={item.extendedProps.companyDisplayName} orderjobtime={item.extendedProps.orderJobTime} eventtextcolor={item.textColor} className={"event badge d-block mb-2 me-2"} eventcolor={item.backgroundColor} style={{backgroundColor: item.backgroundColor, color: item.textColor}} eventtravelerid={item.extendedProps.travellerId}>
									<div className="d-flex justify-content-between">
										{item.title}
										{
											item.extendedProps.travellerBooked != false && (
												<div>
													<i className="pi pi-check" style={{ fontSize: '0.6rem' }}></i>
												</div>
											)
										}
									</div>
									<small style={{fontSize: '0.6rem'}}>{item.extendedProps.companyDisplayName} - #{item.extendedProps.orderNumber}</small>
									{
									item.extendedProps.productSku && item.extendedProps.productQty && (
											<div className="d-flex font-weight-normal" style={{ fontSize: '0.7rem' }}>
												{item.extendedProps.productSku} <div className="mx-1">x</div> <div><span className="badge bg-primary">{item.extendedProps.productQty}</span></div>
											</div>
										)
									}
									{
										item.extendedProps.travellerTargetTime != '00:00:00' && (
											<div className="mt-1">
												<span className={timerColorClass + " badge text-white"}><i className="pi pi-stopwatch me-1" style={{ fontSize: '0.65rem' }} ></i>{item.extendedProps.travellerTargetTime}</span>
											</div>
										)
									}
									{
										item.extendedProps.travellerTargetTime == '00:00:00' && (
											<div className="mt-1">
												<span className={"badge bg-danger text-white"}><i className="pi pi-stopwatch me-1" style={{ fontSize: '0.65rem' }} ></i>Missing Time</span>
											</div>
										)
									}
								</li>)
							})	
						)
					}
				</ul>
			</div>
		</div>
    </div>
	</>);
}
