import React, { Component } from 'react';
import Employees from './charts/employees';
import ProductionOperators from './tables/production-operators';
import AccountManagers from './tables/account-managers';

export class ProductDashboard extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (<>
				<div className="edit-order-bar position-sticky top-0 py-3 mb-5">
					<div className="mx-5 px-0 d-flex justify-content-between align-items-center">
						<div>
							<h1 className="mb-0 h3">{this.props.title}</h1>
						</div>
					</div>
				</div>
				<div className="container px-5 my-5">
				<div className="row">
						<div className="col-4">
							<Employees />
						</div>
						<div className="col-8">
							<ProductionOperators title="Missing Battery Pack Timings" category={this.props.batteryPackId} lowStock={100} />
						</div>
					</div>
					<div className="row mt-5">
						<div className="col-6">
							<AccountManagers />
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default ProductDashboard