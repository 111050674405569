import React from "react";
import OrdersContainer from './orders-container';
import { useLocation } from 'react-router-dom';

export default function CompanyOrdersContainerWrapper(props) {
	const paths = useLocation().pathname.split('/');

	function statusChanged(oldStatus, newStatus) {
		props.updateAppStatusCount(oldStatus, newStatus);
	}

	function sidebarOpened() {
		props.updateSidebarOpened(true);
	}

	return (
		<OrdersContainer pageTitle="Sales Orders" currentLoggedInUser={props.currentLoggedInUser} companyId={paths[paths.length - 1]} capabilities={props.capabilities} availableStatuses={props.availableStatuses} lastSynced={props.lastSyncedOrders} status="sales-orders" onStatusChange={statusChanged} updateSidebarOpened={sidebarOpened} />
	)
}