import React, { Component } from 'react';
import axios from 'axios';

import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';

import { Toast } from 'primereact/toast';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getUrl } from '../planner/planner';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';

export class Processes extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			processes: [],
			selectedRows: [],
			isLoaded: false,
			binDialog: false,
			processDialog: false,
			newProcessName: "",
			newProcessDescription: "",
			rowCount: 0,
			toast: React.createRef(),
			errorToast: React.createRef()
		}

		this.addRow = this.addRow.bind(this); 
		this.setName = this.setName.bind(this); 
		this.setDescription = this.setDescription.bind(this); 
		this.nameEditor = this.nameEditor.bind(this); 
		this.descriptionEditor = this.descriptionEditor.bind(this); 
	}

	/**
	 * When the component is loaded get the data for the processes
	 */
	componentDidMount() {
		document.title = 'Processes - Planner';

		axios.get(getUrl() + '/wp-json/planner/v1/travellers/processes', { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then(res => this.setState({
			processes: res.data,
			isLoaded: true
		})).catch(err => {
			this.state.errorToast.current.show({ severity: 'error', summary: 'Oops 🤦‍♂️', sticky: true, detail: 'An error has occurred, please contact IT support.' });
			console.log(err)
		});
	}

	/**
	 * Set the state of the new process description to the input
	 * 
	 * @param {Object} e Information related to the input being edited
	 */
	setDescription(e) {
		this.setState({
			newProcessDescription: e.target.value
		});
	}

	/**
	 * Set the state of the new process name to the input
	 * 
	 * @param {Object} e Information related to the input being edited
	 */
	setName(e) {
		this.setState({
			newProcessName: e.target.value
		});
	}

	/**
	 * When user is finished editing update the state of the product
	 * 
	 * @param {Object} e The form event
	 */
	onRowEditComplete(e) {
		let _processes = [...this.state.processes];
		let { newData, index } = e;

		console.log(this.state.processes[index].name);
		console.log(_processes);
		console.log(index);
		//_processes.splice(index, 1);
		console.log(_processes);

		_processes[index] = newData;

		if(this.state.processes[index].name !== newData.name || this.state.processes[index].description !== newData.description) {
			this.state.toast.current.show({ severity: 'success', summary: 'Process', detail: 'Process has been updated.' });

			axios.put(getUrl() + '/wp-json/planner/v1/travellers/processes/'+ newData.term_id, {
				"description": newData.description,
				"name": newData.name
			}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
			.then()
			.catch(err => console.log(err));

			this.setState({
				processes: _processes
			});
		}
	}

	/**
	 * Delete a process from the id
	 * 
	 * @param {int} id The id of the process to be deleted
	 */
	deleteProcess(id) {
		axios.delete(getUrl() + '/wp-json/planner/v1/travellers/processes/'+ id, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then()
		.catch(err => console.log(err));
	}

	/**
	 * Delete all of the processes currently selected
	 */
	deleteRows() {
		this.state.selectedRows.forEach((process) => {
			if(process.term_id != null) {
				this.deleteProcess(process.term_id);
			}
		});

		// Delete process on frontend
		let newProcesses = this.state.processes;

		// Remove deleted processes in the users state
		for(var i = 0; i < this.state.selectedRows.length; i++) {
			for(var j = 0; j < this.state.processes.length; j++) {
				if(this.state.processes[j].term_id === this.state.selectedRows[i].term_id) {
					newProcesses.splice(j, 1);
				}
			}
		}

		this.setState({
			selectedRows: [],
			processes: newProcesses
		});

		this.state.toast.current.show({severity: 'success', summary: 'Process', detail: 'Process has been deleted.'});
	}

	/**
	 * Output the HTML to enable the user to change the process time
	 * 
	 * @param {Object} options The information for editing the data in the row
	 * @returns {html} Input so user can change the seconds for the process
	 */
	nameEditor(options) {
		return (
			<InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} className="w-100" />
		);
	}

	/**
	 * Output the HTML to enable the user to change the process time
	 * 
	 * @param {Object} options The information for editing the data in the row
	 * @returns {html} Input so user can change the seconds for the process
	 */
	descriptionEditor(options) {
		return (
			<InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} className="w-100" />
		);
	}

	/**
	 * Adds a new row to the processes table.
	 * 
	 * @param {Object} e The form event
	 */
	async addRow(e) {
		e.preventDefault();

		if(this.state.newProcessName) {
			const [getNewTerm] = await Promise.all([
				axios.post(getUrl() + '/wp-json/planner/v1/travellers/processes/', {
					"name" : this.state.newProcessName,
					"description" : this.state.newProcessDescription
				}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then().catch(err => console.log(err))
			]);

			if(getNewTerm) {
				this.state.processes.push({
					name: this.state.newProcessName,
					description: this.state.newProcessDescription,
					term_id: getNewTerm.data.term_id
				});
	
				this.setState({
					newProcessDescription: '',
					newProcessName: '',
					processDialog: false
				});

				this.state.toast.current.show({ severity: 'success', summary: 'Process', detail: 'Process has been added.' });	
			} else {
				this.state.toast.current.show({ severity: 'error', summary: 'Process', detail: 'Could not create process.' });
			}
		} else {
			this.state.toast.current.show({ severity: 'error', summary: 'Process', detail: 'Process name missing.' });
		}
	}

	getProcessesTitle() {
		let title = this.props.title
		
		if(this.props.capabilities.edit_processes !== true) {
			title = 'Access Denied'
		}

		return title;
	}

	render() {
		const { errorToast, isLoaded, selectedRows, binDialog, toast, processDialog } = this.state;

		let loadingClass = 'isLoading processes-table';

		if(isLoaded) {
			loadingClass = 'notLoading processes-table';
		}

		return (
			<>
				<Dialog blockScroll={true} header="Add Process" style={{ width: '50vw' }} visible={processDialog} onHide={() => this.setState({processDialog: false})}>
					<div className="container px-0">
						<div className="row mb-2">
						<div className="form-group col">
								<label className="d-block"><strong>Process:</strong>
									<InputText type="text" value={this.state.newProcessName} onChange={this.setName} className="w-100" />
								</label>
							</div>
							<div className="form-group col">
								<label className="d-block"><strong>Description:</strong>
									<InputText type="text" value={this.state.newProcessDescription} onChange={this.setDescription} className="w-100" />
								</label>
							</div>
							<div className="col-auto d-flex justify-content-end align-items-end">
								<div>
									<Button onClick={this.addRow.bind(this)}>Add Process</Button>
								</div>
							</div>
						</div>
					</div>
				</Dialog>
				<ConfirmDialog blockScroll={true} visible={binDialog} onHide={() => this.setState({binDialog: false})} message="Are you sure you want to delete selected processes?" header="Confirmation" icon="pi pi-exclamation-triangle" accept={this.deleteRows.bind(this)} />
				<Toast ref={toast} position="bottom-right" />
				<Toast ref={errorToast} position="center" />

				<div className="edit-order-bar position-sticky top-0 py-3 mb-5" >
					<div className="mx-5 px-0 d-flex justify-content-between align-items-center w-100" style={{maxWidth: "100%"}}>
						<div className="d-flex align-items-center">
							<div>
								<Button className="bg-primary p-2 me-3 d-flex align-items-center rounded d-xxl-none" onClick={e => {this.props.updateSidebarOpened(true);}}>
									<i className="pi pi-bars text-white" style={{ fontSize: '1.25rem' }}></i>
								</Button>
							</div>
							<div> 
								<h1 className="mb-0 h3">{this.getProcessesTitle()}</h1>
								{
									this.props.capabilities.edit_processes === true && (
										<><strong>Total Processes:</strong> {this.state.processes.length}</>
									)
								}
							</div>
						</div>
						<div>
							{
								this.props.capabilities.edit_processes === true && (<>
									<Button className="me-3" onClick={() => this.setState({processDialog: true})}>Add Process</Button>
									{
										selectedRows.length > 0 && (
											<Button onClick={() => this.setState({binDialog: true})} icon="pi pi-eraser" className="p-button-danger">&nbsp;Delete Process</Button>
										)
									}		
								</>)
							}
						</div>
					</div>
				</div>
				{
					this.props.capabilities.edit_processes && ( // https://stackoverflow.com/questions/70682832/
					<>
						<div className="card mx-5 mb-5">
							{
								!isLoaded && (
									<div className={"mx-5 mb-3"} style={{ position: 'absolute', top: '3rem', zIndex: '99999', left: '45%' }}>
										<i className="pi pi-spin pi-spinner" style={{ fontSize: '6rem' }}></i>
									</div>
								)
							}
							<DataTable className={loadingClass} paginator rows={20} rowsPerPageOptions={[20, 50]} sortField="term_id" sortOrder={-1} editMode="row" onRowEditComplete={this.onRowEditComplete.bind(this)} dataKey="term_id" size="small" value={this.state.processes} tableStyle={{ minWidth: '50rem' }} selectionMode='checkbox' selection={selectedRows} onSelectionChange={(e) => this.setState({selectedRows: e.value})}>
								<Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
								<Column key="term_id" field="term_id" header="Process #" />
								<Column key="name" field="name" header="Process" editor={this.nameEditor} />
								<Column key="description" field="description" header="Description" editor={this.descriptionEditor} style={{minWidth: '25rem'}}/>
								<Column rowEditor headerStyle={{ width: '5%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'right' }}></Column>
							</DataTable>
						</div>
					</>
					)
				}
			</>
		);
	}
}
export default Processes