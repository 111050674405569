import React, { Component } from 'react';
import axios from 'axios';

import OrderStatus from './charts/order-statuses';
import OrderTiming from './charts/order-timing';
import ProductionOrders from './tables/in-production';
import Shortages from './tables/shortages';
import PriorityOrders from './tables/priority';
import { getUrl, formatDate } from '../planner/planner';

export class Homepage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			lastSynced: '',
			lateOrders: 0,
			onTimeOrders: 0,
		}
	}

	componentDidMount() {
		axios.get(getUrl() + '/wp-json/wc/v3/planner/settings', { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then(res => this.setState({
			lastSynced: res.data.last_synced_orders,
			lateOrders: res.data.late_orders,
			onTimeOrders: res.data.on_time_orders
		})).catch(err => console.log(err));
	}

	render() {
		return (<>
				<div className="edit-order-bar position-sticky top-0 py-3 mb-5">
					<div className="mx-5 px-0 d-flex justify-content-between align-items-center">
						<div>
							<h1 className="mb-0 h3">{this.props.title}</h1>
							<strong>Last Synced:</strong>&nbsp;{formatDate(this.state.lastSynced, true)}
						</div>
					</div>
				</div>
				<div className="container px-5 my-5">
				<div className="row mb-5">
						<div className="col-4">
							<OrderStatus />
						</div>
						<div className="col-8">
							<ProductionOrders />
						</div>
					</div>
					<div className="row mb-5">
						<div className="col-6">
							<PriorityOrders />
						</div>
						<div className="col-6">
							<Shortages />
						</div>
					</div>
					<div className="row">
						<div className="col-4">
							<OrderTiming lateOrders={this.state.lateOrders} onTimeOrders={this.state.onTimeOrders}/>
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default Homepage