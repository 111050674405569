import React from "react";
import EditScrapForm from './edit-scrap-form';
import { useLocation } from 'react-router-dom';

export default function ScrapWrapper(props) {
	const paths = useLocation().pathname.split('/');

	function sidebarOpened() {
		props.updateSidebarOpened(true);
	}

	return (
		<EditScrapForm currentLoggedInUser={props.currentLoggedInUser} id={paths[paths.length - 1]} capabilities={props.capabilities} onSidebarOpen={sidebarOpened} />
	)
}