import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import { Card } from 'primereact/card';
import axios from 'axios';
import { getUrl } from '../../planner/planner';

export default function OrderStatuses() {
	const [statistics, setStatistics] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [chartData, setChartData] = useState({});
	const [chartOptions, setChartOptions] = useState({});

		function get_stats() {
			if(!loaded) {
				axios.get(getUrl() + '/wp-json/wc/v3/reports/orders/totals', { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then(res => setStatistics(res.data), setLoaded(true)).catch(err => console.log(err));
			}
		}

		get_stats();

    useEffect(() => {
				var labels = [];
				var chartData = [];

				statistics.map(data => {
					if(data.name !== 'Draft' && data.name !== 'Completed' && data.name !== 'Cancelled') {
						labels.push(data.name)
					}
				});

				statistics.map(data => {
					if(data.name !== 'Draft' && data.name !== 'Completed' && data.name !== 'Cancelled') {
						chartData.push(data.total)
					}
				});

				const data = {
						labels: labels,
						datasets: [
								{
										data: chartData,
										backgroundColor: [
											"#44484e",
											"#8c1414",
											"#999",
											"#ffe5b4",
											"#ff0",
											"#ff8C00",
											"#ff8C00",
											"#093",
										]
								}
						]
				};

				const options = {
					plugins: {
						legend: {
							labels: {
									usePointStyle: true,
							},
							position: 'bottom'
						},
						title: {
							display: true,
							text: 'Order Status',
							font: {
								size: 22,
								weight: 400
							}
						}
					}
        };

        setChartData(data);
        setChartOptions(options);
    }, [statistics]);

    return (
        <Card className="order-status-cart">
          <Chart type="doughnut" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
        </Card> 
    )
}